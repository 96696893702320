import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PortalAttestationService } from './provider-report.service';
import { ProviderAttestationService } from '../ProviderAttestation/provider-attestation.service'
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { IAttestationPeriod } from '../../app-store/entities/attestationPeriod';
import { IAttestationReportData, IAttestationReportModel, IAttestationReporFiltertModel } from '../../app-store/entities/attestationReport';
import { Page } from '../../app-store/entities/page';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS, DOWNLOAD_PAGE_SIZE} from '../../core/constants/index';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-provider-report-list',
    templateUrl: './provider-report.component.html',
    styleUrls: ['./provider-report.component.scss'],
    standalone: false
})

export class ProviderReportComponent implements OnInit {
  constructor(
    private fb:FormBuilder, 
    private portalAttestationService: PortalAttestationService,
    private providerAttestationService: ProviderAttestationService,
    private datePipe:DatePipe
    ) { }
  copyright: string = environment.copyright; 
  // Cards
  formGroup!: FormGroup;
  isLoading: boolean=false;  
  attestationPeriod$!: IAttestationPeriod[]; 
  attPeriod!: string;
  providerCount!: number;
  AttestationCompletedWithChangeCount!: number;
  AttestationCompletedWithoutChangeCount!: number;
  attestationDueCount!: number;
  secondaryOutreachCount!: number;
  tertiaryOutreachCount!: number;
  exceptionrCount!: number;
  failureCount!: number;
  FailedEmailOutreachCount!: number;
  FailedFaxOutreachCount!: number;

  // Rows
  currentAttestationPeriod$: string; 
  reportFilter!: string;
  filterParameterData: IAttestationReporFiltertModel;
  reportAttestations$: IAttestationReportModel[] =[];
  rows = [];
  selected = [];
  currentPageSize!:number
  currentOrderBy!:string;
  currentOrderByDescending!:string
  currentPageNumber!:number;
  pageSizeOptions:number[]=[];
  columns = [      
    {
    prop: 'selected',
    Name: '',
    sortable: true,
    canAutoResize: false,
    draggable: false,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    width: 30
  }];
  ColumnMode = ColumnMode;
  page = new Page();
  isloading: boolean=false;
  totalCount:number = 0;
  
  @ViewChild(DatatableComponent) table!: DatatableComponent;

  ngOnInit():void{
    this.initializeForm();
    this.isLoading =true; 
    
    let date: string = this.datePipe.transform(new Date(),"MM-dd-yyyy") ?? "";
    this.providerAttestationService.getCurrentAttestationPeriod(date).subscribe({
      next: async(response: string)=> {              
        this.formGroup.get('ddlAttestationPeriod')?.setValue(response);
        this.getReportCardCount();
        this.getAttestationReport('TotalProviders');
      },
      error: (e) => {
        console.error('Attestation period request failed with error - ' + JSON.stringify(e));                    
      }
    });    
  }

  initializeForm():void{
    this.getAttestaionPeriod();
    this.formGroup = this.fb.group({
      ddlAttestationPeriod: new FormControl(''),
      ddlPageSizeOptions:new FormControl()
    });
    this.formGroup.get('ddlAttestationPeriod')?.setValue('');
    let ddlPageSizeOptions = this.formGroup.get('ddlPageSizeOptions') as FormControl;
    ddlPageSizeOptions.patchValue(DEFAULT_PAGE_SIZE);
  }

  getAttestaionPeriod(){
    this.portalAttestationService.getAttestationPeriod().subscribe({
      next: async (response :IAttestationPeriod[]) => {
        this.attestationPeriod$ = response;
        this.isLoading=false;
      },
      error: (e) => {
        console.error('Get attestationPeriod request failed with error - ' + e);
        this.isLoading=false;
      },
      complete: () => {
       console.info('Get attestationPeriod request completed !!!') 
        this.isLoading=true;
      }
    });
   }  

  getReportCardCount(){    
    this.attPeriod = this.formGroup.get('ddlAttestationPeriod')?.value;  
    //console.log(this.attPeriod);
    if (this.attPeriod != null && this.attPeriod != undefined && this.attPeriod != '') {   
      this.getReportDataCount();    
      this.getAttestationReport(this.reportFilter);      
    }   
  }

  getReportDataCount(){
    this.portalAttestationService.getReportDataCount(this.attPeriod).subscribe({
      next: async (response) => {
        if (response != null && response != undefined && response.length > 0) {
          this.providerCount = response.filter(x => x.countType == 'Provider')[0].count;
          this.AttestationCompletedWithChangeCount = response.filter(x => x.countType == 'AttestationCompletedWithChange')[0].count;
          this.AttestationCompletedWithoutChangeCount = response.filter(x => x.countType == 'AttestationCompletedWithoutChange')[0].count;
          this.attestationDueCount = response.filter(x => x.countType == 'AttestationDue')[0].count;
          this.secondaryOutreachCount = response.filter(x => x.countType == 'NotificationSecondary')[0].count;
          this.tertiaryOutreachCount = response.filter(x => x.countType == 'NotificationTertiary')[0].count;
          this.exceptionrCount = response.filter(x => x.countType == 'Exception')[0].count;
          this.failureCount = response.filter(x => x.countType == 'Failure')[0].count; 
          this.FailedEmailOutreachCount = response.filter(x => x.countType == 'FailedEmailOutreach')[0].count; 
          this.FailedFaxOutreachCount = response.filter(x => x.countType == 'FailedFaxOutreach')[0].count; 
        }
        
        this.isLoading=false;
      },
      error: (e) => {
        console.error('getReportDataCount request failed with error - ' + e);
        this.isLoading=false;
      },
      complete: () => {
       console.info('getReportDataCount request completed !!!') 
        this.isLoading=true;
      }
    });
  } 

  getAttestationReport(filter: string){
    this.reportFilter = filter;
    this.isloading =true;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS; 
    this.currentPageSize = this.currentPageSize == undefined ? DEFAULT_PAGE_SIZE: this.currentPageSize;
    this.currentPageNumber = this.currentPageNumber == undefined ? 1 : this.currentPageNumber;
    this.currentOrderBy='name';
    this.currentOrderByDescending='asc';    
    this.setFilterParameter();
    this.getAttestationReportData(this.filterParameterData);
  }

  downloadAttestationReport(){
    this.isloading =true;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS; 
    this.currentPageSize = DOWNLOAD_PAGE_SIZE;
    this.currentPageNumber = 1;
    this.currentOrderBy='name';
    this.currentOrderByDescending='asc';    
    this.setFilterParameter();
    this.downloadAttestationReportData(this.filterParameterData);
  }

  setFilterParameter(){
   
    switch(this.reportFilter) { 
      case 'TotalProviders': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: undefined, attempt: undefined, failure: undefined, activeOutbound:undefined,  pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      } 
      case 'AttestationCompleted': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: 1, attempt: undefined, failure: undefined, activeOutbound:undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      } 
      case 'AttestationDue': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: 0, attempt: undefined, failure: undefined, activeOutbound:undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      }
      case 'SecondaryOutreach': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: undefined, attempt: 2, failure: undefined, activeOutbound:undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      }
      case 'TertiaryOutreach': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: undefined, attempt: 3, failure: undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      }
      case 'Exceptions': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: 1, isAttested: undefined, attempt: undefined, failure: undefined, activeOutbound:undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      }
      case 'Failures': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: undefined, attempt: undefined, failure: "Y", activeOutbound:undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      } 
      case 'ActiveOutbound': { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: undefined, attempt: undefined, failure: undefined, activeOutbound: 1, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      }                              
      default: { 
        this.filterParameterData= { attestationPeriod: this.formGroup.get('ddlAttestationPeriod')?.value, hasException: undefined, isAttested: undefined, attempt: undefined, failure: undefined, activeOutbound:undefined, pageNumber: this.currentPageNumber, pageSize: this.currentPageSize, sortColumn: this.currentOrderBy, sortOrder: this.currentOrderByDescending }
        break; 
      } 
    }      
  }

  getAttestationReportData(searchFilterData: IAttestationReporFiltertModel){
    this.isloading=true;
    this.portalAttestationService.getAttestationReportDetails(searchFilterData).subscribe({
        next: async (response :IAttestationReportModel[]) => {          
          this.reportAttestations$ = response;    
          if (response != null && response != undefined && response.length > 0) {      
            this.totalCount= response[0].totalRowCount ?? 0;
          }else{
            this.totalCount=0;
          }
        },
        error: (e) => {
          console.error('getAttestationReportDetails request failed with error - ' + e);                    
        },
        complete: () => {
          console.info('getAttestationReportDetails request completed !!!')  
          this.isloading=false        
        }
      });
   }

   downloadAttestationReportData(searchFilterData: IAttestationReporFiltertModel){
    this.isloading=true;
    this.portalAttestationService.downloadAttestationReportDetails(searchFilterData).subscribe({
        next: async (response : Blob) => {            
              const url= window.URL.createObjectURL(response);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'Attestations_' + this.datePipe.transform(new Date(),"MM-dd-yyyy") + '_'+ this.reportFilter + '.xlsx');
              document.body.appendChild(link);
              link.click();
          },
          error: (e) => {
            console.error('downloadAttestationReportDetails request failed with error - ' + e);                    
          },
          complete: () => {           
            console.info('downloadAttestationReportDetails request completed');                    
            this.isloading=false        
          }
        });
    }    

   onPageSizeOptionChange(value: any){
     if (value !=null && value !=undefined)
     {
       this.currentPageSize = value.target.value;
       this.currentPageNumber = 1;  
       this.setFilterParameter();      
       this.getAttestationReportData(this.filterParameterData);
     }    
   }
 
   onSort(event:any){   
      if (event !=null && event!=undefined && event.sorts.length!=0)
      {
       if ( event.sorts[0].prop=='firstName' )
       {
         this.currentOrderBy = 'Name';
         this.currentOrderByDescending = event.sorts[0].dir;
       }
       else
       {
         this.currentOrderBy = (event.sorts[0].prop).replace('providerDetails.','').replace('attestationDetails.','');
         this.currentOrderByDescending = event.sorts[0].dir;
       }       
      }    
      this.currentPageNumber = 1;
      this.setFilterParameter();       
      this.getAttestationReportData(this.filterParameterData);       
    }
  
    onFooterPage(event:any){    
      if (event !=null && event !=undefined){
       this.currentPageNumber =(event.page);
     }  

     this.setFilterParameter();  
     this.getAttestationReportData(this.filterParameterData);
    }   
}