import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent, SortType } from '@swimlane/ngx-datatable';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IProviderIdentity } from 'src/app/app-store/entities/user';
import { UserService } from 'src/app/app-store/services/user.service';
import { CurrentStorageService } from 'src/app/core/services/current-storage.service';
import { ProviderAttestationService } from '../ProviderAttestation/provider-attestation.service'
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    standalone: false
})

export class SelectComponent implements OnInit {

  providers: IProviderIdentity[];
  columns = [      
    {
    prop: 'selected',
    Name: '',
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizable: false,
    headerCheckboxable: false,
    checkboxable: false,    
  }];
  ColumnMode = ColumnMode;
  tin: string;
  selectedProviderId: number;
  isAttestationPeriodClosed:boolean = false;
  contactEmail: string = environment.contactEmail; 
  copyright: string = environment.copyright; 

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private currentStorageService: CurrentStorageService,
    private attestationService: ProviderAttestationService) { }

  ngOnInit(): void {
    
    this.tin = this.currentStorageService.TIN;
    this.selectedProviderId = this.currentStorageService.SelectedProviderId;    
    
    this.oidcSecurityService.isAuthenticated$.subscribe({
      next: () =>  {
        this.oidcSecurityService.getAccessToken().subscribe({
          next: (accessToken) => {
            this.attestationService.isCurrentAttestationPeriodClosed().subscribe({
              next: async(response: string)=> {
                this.isAttestationPeriodClosed = (response == "true");
                //console.log("isAttestationPeriodClosed: " + this.isAttestationPeriodClosed);
                this.userService.getProviderNpi(accessToken).subscribe({
                  next: (data) => {
                    this.providers = data;
                    if(this.providers.length == 1){
                      if (this.isAttestationPeriodClosed == true)
                        this.router.navigate(['/forbidden/5']);
                      else
                        this.showDetails(this.providers[0]);                  
                    }                          
                  },
                  error: (error) => {
                    console.error('Error fetching data:', error);
                  }      
                });    
              },
            error: (error) => {
              console.error('Error fetching attestation period status: ', error);
            }
          });
            
          }
        });
      }
    });    
    
  }

  showDetails(row:IProviderIdentity){
    this.currentStorageService.NPI =row.npi
    this.currentStorageService.TIN =row.tin;
    this.currentStorageService.SelectedProviderId = row.providerId;
    this.router.navigate(['ProviderAttestationForm']);
  }
}