import {
  Component,
  ContentChild,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
  Input,
} from '@angular/core';
import { FooterService } from './footer.service';

/**
 * Footer Left
 */
@Component({
    selector: 'lte-layout-footer-left',
    template: '<ng-template #templateRef><ng-content></ng-content></ng-template>',
    standalone: false
})
export class FooterLeftComponent {
  @ViewChild('templateRef', { static: true })
  public templateRef!: TemplateRef<any>;
}

/**
 * Footer Right
 */
@Component({
    selector: 'lte-layout-footer-right',
    template: '<ng-template #templateRef><ng-content></ng-content></ng-template>',
    standalone: false
})
export class FooterRightComponent {
  @ViewChild('templateRef', { static: true })
  public templateRef!: TemplateRef<any>;
}

@Component({
    selector: 'lte-layout-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit {
  @ContentChild(FooterLeftComponent, { static: true })
  public footerLeftComponent!: FooterLeftComponent;
  @ContentChild(FooterRightComponent, { static: true })
  public footerRightComponent!: FooterRightComponent;
  @Input() public isUserLayout!: boolean;
  constructor(
    private elementRef: ElementRef,
    private footerService: FooterService
  ) {}

  ngOnInit() {
    this.footerService.elementRef = this.elementRef;
  }
}
