import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
    standalone: false
})
export class ForbiddenComponent implements OnInit {
  messageId: number = 0;
  message: string = '';
  contactEmail: string = environment.contactEmail; 
  copyright: string = environment.copyright; 
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    //debugger;
    this.messageId = +this.route.snapshot.params['messageId'];
    switch(this.messageId) { 
      case 1: { 
        this.message = 'No provider attestation(s) associated with your account.'
         break; 
      } 
      case 2: { 
        this.message = 'This attestation link is no longer active.  Please attest using the link in your most recent email.'
         break; 
      } 
      case 3: { 
        this.message = 'The tax identification number (TIN) and national provider identifier (NPI) entered do not match this attestation request.'
         break; 
      } 
      case 4: { 
        this.message = 'Your account has been disabled.'
         break; 
      } 
      case 5: { 
        this.message = 'This attestation link is no longer active. Please wait for the next attestation period. A new link from OptumCAAttestations@optum.com will be sent to your email.'
         break; 
      } 
      default: { 
        this.message = 'You are not authorized to view this page.'
         break; 
      } 
   } 
  }
}
