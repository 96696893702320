import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextValidators } from '../../shared/validators/text.validator';
import { CurrentStorageService } from 'src/app/core/services/current-storage.service';
import { ICommandResult } from 'src/app/app-store/entities/common';
import { IProviderIdentity, IRegistrationChallengeResult } from 'src/app/app-store/entities/registration';
import { RegistrationService } from '../registration/registration.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    standalone: false
})

export class RegistrationComponent implements OnInit {
  formGroup!: FormGroup;
  returnUrl!: string;
  errorMessage!:string;
  error:boolean = false;
  token: string;
  registrationCode: string;
  verificationTin: string;
  verificationNpi: string;
  verificationAttempt: number = 1;
  verifiedProviderId: number = 0;
  step:string = 'verify';
  //step:string = 'select';
  hideRoleBack:boolean = false;
  hideSelectBack:boolean = false;
  registerSameDetails:boolean = false;
  userRole:string = '';
  copyright: string = environment.copyright;
  selection: SelectionType;
  columnMode = ColumnMode;
  verifiedVendorName: string;

  providerHistoryColumns = [];
  providerHistoryData: IProviderIdentity[] = [];
  @ViewChild(DatatableComponent) providerHistoryTable!: DatatableComponent;

  selected: any[] = [];
  selectColumns = [];
  providerSelectData: IProviderIdentity[] = [];
  providerSelectTempData: IProviderIdentity[] = [];
  @ViewChild(DatatableComponent) selecttable!: DatatableComponent;

  confirmColumns = [];
  providerConfirmData: IProviderIdentity[] = [];
  @ViewChild(DatatableComponent) confirmtable!: DatatableComponent;
  
  keyHandlers: { [key: string]: () => void };

  constructor(
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private currentStorageService: CurrentStorageService,
    private registrationService: RegistrationService,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.selection = SelectionType.checkbox;
    this.keyHandlers = {
      'Enter': () => this.verify(),
    };
  }

  ngOnInit(): void {
    //debugger;
    this.registrationCode = this.route.snapshot.params['registrationCode'];
    if (this.registrationCode == undefined && this.registrationCode == "") {
      console.log('registrationCode missing');
      this.router.navigate(['/forbidden']);
    } 
    this.formGroup = this.fb.group({
      txtTIN: ['', [Validators.required,  Validators.maxLength(50), TextValidators.notEmpty],],
      txtNPI: ['',[Validators.required,  Validators.maxLength(50),TextValidators.notEmpty],],
    });
    this.currentStorageService.TIN = '';
    this.currentStorageService.SelectedProviderId = 0
    this.currentStorageService.NPI = '';
  }

  verify(): void {   
    this.error = false;
    this.errorMessage = '';
    this.verificationTin = this.formGroup.get('txtTIN')?.value;
    this.verificationNpi = this.formGroup.get('txtNPI')?.value;
    if (this.verificationTin != undefined && this.verificationTin != "" && this.verificationNpi != undefined && this.verificationNpi != "") {
      this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
        this.token = accessToken;
        this.registrationService.verifyRegistration(this.verificationTin, this.verificationNpi, this.registrationCode, this.token).subscribe({
          next: async (challengeResult: IRegistrationChallengeResult) => {
            //debugger;  
            if (challengeResult != undefined && challengeResult.successful == true) {
              this.currentStorageService.TIN = challengeResult.tin;
              if (challengeResult.availableProviders != undefined && challengeResult.availableProviders?.length > 0) {
                this.verifiedProviderId = challengeResult.verifiedProviderId;
                this.providerSelectData = challengeResult.availableProviders?.filter(p => p.tin == challengeResult.tin);
                this.providerSelectTempData = challengeResult.availableProviders?.filter(p => p.tin == challengeResult.tin);
                this.selected.push(this.providerSelectData?.filter(p => p.providerId == challengeResult.verifiedProviderId)[0]);
              }
              if (challengeResult.availableProviders != undefined && challengeResult.providerHistory != undefined && challengeResult.providerHistory?.length > 1) {
                if (challengeResult.userRoleId == 1) {
                  //if they are a provider with previous registrations, save additional tins for npi, and route to the select screen
                  this.userRole = 'provider';
                  this.roleSelect('next', true);
                } else {
                  let currentPeriod = challengeResult.availableProviders[0].attestationPeriod;
                  //always has one from validation save
                  let currentProviders = challengeResult.providerHistory.filter(p => p.attestationPeriod == currentPeriod);
                  if (currentProviders.some(p => p.providerId != challengeResult.verifiedProviderId)) {
                    if (this.verifiedProviderId > 0 && this.providerSelectData?.length == 1) {
                      console.log('registered for other providers, single npi, done with registration.');
                      this.router.navigate(['/select']); 
                    } else {
                      console.log('registered for other providers, multi npi, next step select.');
                      this.hideSelectBack = true;
                      this.step = 'select';
                      this.verifiedVendorName = this.providerSelectData[0].vendorName == "" ? "Organization" : this.providerSelectData[0].vendorName;
                    }
                  } else {
                    let previousProviders = challengeResult.providerHistory.filter(p => p.attestationPeriod != currentPeriod);
                    let attestionPeriods = [...new Set(previousProviders.map(p => p.attestationPeriod)) ];
                    let nextPeriod = attestionPeriods.sort().reverse()[0];
                    this.providerHistoryData = challengeResult.providerHistory.filter(p => p.attestationPeriod == nextPeriod);
                    this.verifiedVendorName = this.providerHistoryData[0].vendorName == "" ? "Organization" : this.providerHistoryData[0].vendorName;
                    this.step = 'previous';
                  }
                }
              } else {
                console.log('no provider history, next step role.');
                this.hideRoleBack = true;
                this.step = 'role';
              }
            } else {
              this.error = true;
              this.errorMessage = 'The tax identification number (TIN) and national provider identifier (NPI) entered do not match this attestation request.';
              if (this.verificationAttempt >= 3) {
                console.log('3rd attempt failed');
                this.router.navigate(['/forbidden/3']); 
              }
              this.verificationAttempt++;
            }
          },
          error: (e) => {
            //debugger;   
            console.error('verify registration request failed with error - ' + JSON.stringify(e));
          }
        });
      });
    } else {
      this.error = true;
      this.errorMessage = 'Please enter valid TIN and NPI.';
    }
  }

  sameDetails():void{
    console.log('same details' + this.registerSameDetails);
    this.error = false;
    this.errorMessage = '';
    if (this.registerSameDetails == true) {
      console.log('saving same details.');
      if (this.verificationTin != undefined && this.verificationTin != "" && this.verificationNpi != undefined && this.verificationNpi != "") {
        this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
          this.token = accessToken;
          this.registrationService.registerPrevious(this.verificationTin, this.verificationNpi, this.registrationCode, this.token).subscribe({
            next: async (result: ICommandResult) => {
              //debugger;  
              if (result != undefined && result.success == true) {
                console.log(result.message);
                this.router.navigate(['/select']); 
              } 
              else { 
                this.error = true;
                if (result != undefined) {
                  this.errorMessage = result.message;
                }
              }
            },
            error: (e) => {
              //debugger;   
              console.error('register previous request failed with error - ' + JSON.stringify(e));
            }
          });
        });
      }
    } else {
      console.log('not saving same details, next step select.');
      this.step = 'role';
    }
  }

  roleSelect(navigation: string, bypassedUI: boolean):void {
    //debugger;
    console.log('roleSelect click: ' + navigation);
    console.log('roleSelect role: ' + this.userRole);
    if (navigation == 'next') {
      console.log('saving role.');
      if (this.verificationTin != undefined && this.verificationTin != "" && this.verificationNpi != undefined && this.verificationNpi != "") {
        this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
          this.token = accessToken;
          this.registrationService.assignRole(this.verificationTin, this.verificationNpi, this.registrationCode, this.userRole, this.token).subscribe({
            next: async (result: ICommandResult) => {
              //debugger;  
              if (result != undefined && result.success == true) {
                console.log(result.message);
                if (bypassedUI == true || this.userRole == 'provider') {
                  console.log('privder selected, saving and routing to select screen.');
                  this.router.navigate(['/select']); 
                } else {
                  if (this.verifiedProviderId > 0 && this.providerSelectData?.length == 1) {
                    this.currentStorageService.SelectedProviderId = this.verifiedProviderId;
                    this.router.navigate(['/select']); 
                  } else {
                    console.log('administrative, next step select.');
                    this.step = 'select';
                  }
                } 
              } 
              else { 
                this.error = true;
                if (result != undefined) {
                  this.errorMessage = result.message;
                }
              }
            },
            error: (e) => {
              //debugger;   
              console.error('vassign role request failed with error - ' + JSON.stringify(e));
            }
          });
        });
      }
    } 
    else {
      this.step = 'previous';
    }
  }

  providerSelect(navigation: string):void{
    console.log('providerSelect click: ' + navigation);
    if (navigation == 'next') {
      this.providerConfirmData = [...this.selected];
      this.step = 'confirm';
    } 
    else {
      this.step = 'role';
    }
  }
  keyPressValidation(event:any){
    let isvalid! : boolean;
    
      var str = String.fromCharCode(event.keyCode);
      let pattern = new RegExp("[0-9]");       
      if (pattern.test(str)) {
        isvalid=true;
      } 
      else 
      {
        event.preventDefault();
        isvalid=false;          
      }
    
    return isvalid;
  }
  providerConfirm(navigation: string):void {
    console.log('providerConfirm click: ' + navigation);
    console.log('providerSelect click: ' + navigation);
    if (navigation == 'next') {
      if (this.verificationTin != undefined && this.verificationTin != "" && this.verificationNpi != undefined && this.verificationNpi != "") {
        this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
          this.token = accessToken;
          let selectedProviders: IProviderIdentity[] = [...this.selected.map(p => { return <IProviderIdentity> { providerId: p.providerId, tin: p.tin, npi: p.npi } })];
          this.registrationService.saveSelectedProviders(this.verificationTin, this.verificationNpi, this.registrationCode, selectedProviders, this.token).subscribe({
            next: async (result: ICommandResult) => {
              //debugger;  
              console.log(result.message);
              if (result != undefined && result.success == true) {
                this.router.navigate(['/select']); 
              } 
              else { 
                this.error = true;
                if (result != undefined) {
                  this.errorMessage = result.message;
                }
              }
            },
            error: (e) => {
              //debugger;   
              console.error('save selected providers request failed with error - ' + JSON.stringify(e));
            }
          });
        });
      }
    } 
    else {
      this.providerSelectData = [...this.providerSelectTempData];
      this.step = 'select';
    }
  }

  onSelect({ selected }: any) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  updateSelectFilter(event: any) {
    //debugger;
    const val = event.target.value.toLowerCase();
    //console.log(val);
    const temp = this.providerSelectTempData.filter(function (d: any) {
      return d.npi.toLowerCase().indexOf(val) !== -1 || d.fullName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.providerSelectData = temp;
  }
}
