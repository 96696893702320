import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPhone',
    standalone: false
})
export class FormatPhonePipe implements PipeTransform {

  transform(phoneValue: any): string {
    // Remove all non-digits
    let cleaned = ('' + phoneValue).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return phoneValue;
  }

}