import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TextValidators } from '../../shared/validators/text.validator';
import {IProviderAttestationModel, IProviderBoard, IProviderInsurance, IProviderModel, IProviderOfficeInformationModel,IProviderSpecialtyModel, IProviderViewModel } from '../../app-store/entities/provider';
import {IConstant} from '../../app-store/entities/constant';
import {SpecialCharacterValidators} from '../../shared/validators/special-character.validator';
import { ProviderAttestationService } from '../ProviderAttestation/provider-attestation.service'
import { CurrentStorageService } from '../../core/services/current-storage.service';
import { ToastService } from '../../core/services/toast.service';
import { IFormFieldModel,IRange,IPattern } from 'src/app/app-store/entities/formfields';
import { ModalComponent  } from 'src/app/libs/modal/modal.component';
import {AddOfficeHourComponent } from '../ProviderAttestation/Modals/add-office-hours/add-Office-hour.component';
import { BehaviorSubject, EMPTY, delay, of } from 'rxjs';
import moment from 'moment';
import * as CONSTANT from '../../core/constants';
import * as ENUM from '../../core/constants/enums'
import { CommonUtil } from 'src/app/shared/Util/common-util';

@Component({
    selector: 'app-provider-attestation',
    templateUrl: './provider-attestation.component.html',
    styleUrls: ['./provider-attestation.component.scss'],
    standalone: false
})
  export class ProviderAttestationComponent implements OnInit, OnDestroy {
    @ViewChild('btnAttest', { static: false })
    btnAttest!: ElementRef;

    @ViewChild('addOfficeHoursModal', { static: false })
    addOfficeHoursModal!: ModalComponent;   

    @ViewChild('alertConfirmationModal', { static: false })
    alertConfirmationModal!: ModalComponent;   

    @ViewChild('alertAttestationConfirmationModal', { static: false })
    alertAttestationConfirmationModal!: ModalComponent;  

    @ViewChild('addOfficeHourComponent', { static: false })
    addOfficeHourComponent!: AddOfficeHourComponent; 

    initAddOfficeHoursForm = new BehaviorSubject<boolean>(false);
    addOfficeHoursForm!: FormGroup;
    formGroup!: FormGroup;     
    boardSectionGroup!: FormGroup;
    selectedBoardCertificationStatus:boolean=true;
    SelectedMedicareEnrollmentStatus :boolean =true;
    SelectedMedicalEnrollmentStatus :boolean =true;
    SelectedPanelStatus :boolean =true;
    SelectedOfficeType!:string ;
    isDisabled:boolean = true;
    isReadOnly:boolean=false;
    isLoading:boolean= true;
    isAttesteationCompleted:boolean=true;
    isEditable:boolean=false;
    isOfficeInformationSectionEditable:boolean=false;
    isOfficeInformationAddClick:boolean=false;    
    isProviderLicenseSectionEditable:boolean=false; 
    isProviderBoardTrainingSectionEditable:boolean=false;
    isProviderLiabilitySectionEditable:boolean=false;
    isProviderContractInfoSectionEditable: boolean = false
    isSpecialtySectionEditable:boolean=false;
    providerMedicareStatusToDisplay!: string;
    providerMedicalStatusToDisplay!: string;
    providerPanelStatusToDisplay!: string;
    providerCertificationToDisplay!:string;

    //status!:any[];
    //certifications!:any[];
    providerOfficeInformation$!: IProviderOfficeInformationModel[];
    providerSpecialty$: IProviderSpecialtyModel[] =[];
    providerData$!:IProviderViewModel;
    providerAttestationData$!:IProviderAttestationModel;
    providerInsurance$!: IProviderInsurance[];
    providerBoard: IProviderBoard;
    providerDetailsData$!:IProviderViewModel;
    providerFormFields$!: IFormFieldModel[] ;
    patterns$!: IPattern[];
    constants$!:IConstant[];
    panelStatus!:IConstant[];
    medicalStatus!:IConstant[];
    medicareStatus!:IConstant[];
    boardStatus!:IConstant[];
    gender!:IConstant[];
    attestationErrorMessage:string="";
    officeInformationErrorMessage: string='';
    providerLicenseErrorMessage:string='';
    providerLiabilityErrorMessage:string='';
    providerContractInfoErrorMessage:string='';
    specialtyErrorMessage: string ='';
    isValidAgeRange:boolean=true;
    isValidAmountRange:boolean = true;
    selectedLocationId?:number;
    //officeHoursFormAction:string ='';
    officeHours: IRange[] =[];
    showReadonlyModal:boolean=false;
    modalwidth!: string;
    showOfficeHours: boolean = true;
    attestionPeriod!:string;
    isValidDateRange: boolean = true;
    constructor(private fb:FormBuilder,
      private providerAttestationService: ProviderAttestationService,
      private currentStorageService: CurrentStorageService,
      private toast: ToastService,
      private cdr: ChangeDetectorRef
      ){}
    
    ngOnInit(): void {
      this.currentStorageService.userName = ' ';
      this.providerFormFields$ = this.providerAttestationService.getFormFields();
      this.initializeForm();
      this.getProviderData(false);      
      this.onChanges();
      
     }
    
    ngAfterContentChecked() {     
      this.cdr.detectChanges();
    }

     initializeForm()
     {
      //populate all dropdown lists for the page
      this.isReadOnly =false;
      this.getConstants();
      
      this.patterns$ = [
        { key:'A', value: new RegExp("[a-zA-Z ]")}, //uppercase and lowercase letters and space only
        { key:'B', value: new RegExp("[a-zA-Z-, ]")}, //uppercase and lowercase letters only with comma and space
        { key:'C', value: new RegExp("[a-zA-Z0-9]") }, //uppercase and lowercase alphanumerics 
        { key:"D", value: new RegExp("[0-9]")}, // numerics only
        { key:'E', value: new RegExp("[a-zA-Z0-9- ]") }, //uppercase and lowercase alphanumerics, dash and space  
        { key:'F', value: new RegExp("[a-zA-Z0-9-# ]") }, //uppercase and lowercase alphanumerics, dash and space   
        { key:'G', value: new RegExp("[a-zA-Z0-9/]") }, //uppercase and lowercase alphanumerics       
      ];

      this.boardSectionGroup = this.fb.group({
          //SECTION 2
            pb_boardname:[''],
            ddlpb_boardCertificationStatus:new FormControl({ disabled: true}), 
            pb_effectivedate:[''],
            pb_expirationdate:['']
      })

      this.formGroup = this.fb.group({
        //SECTION 1a
        pi_birthdate: ['',[Validators.required, TextValidators.notEmpty],],
        ddlpi_gender: [''],
        pi_email: ['',[Validators.required, Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,4}$'), TextValidators.notEmpty],],
        pi_language: [''],      
        pi_degree: ['',[Validators.required, TextValidators.notEmpty],],          
        pi_organnizationalnpi:[''], 
        pi_emr: [''],
        pi_telehealth:[''],  
        pi_hospitalSurgeryCenterAffiliation: [''],                    
        pi_officeemail: ['',[Validators.required, Validators.pattern('^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\\.[a-zA-z]{2,4}$'), TextValidators.notEmpty],],
        pi_ageMin:['',[Validators.required, TextValidators.notEmpty ],],
        pi_ageMax:['',[Validators.required, TextValidators.notEmpty],],  
        pi_chkPCP: new FormControl(),
        pi_chkSpecialty: new FormControl(),
        pi_chksdao: new FormControl(),

        //SECTION 1b
        pl_medicalnumber:[''],
        pl_medicarenumber:[''], 
        //pl_upin:[''],
        pl_dea:[''],
        pl_statelicense:['',[Validators.required, TextValidators.notEmpty],],
        pl_statelicenseexpdate:['',[Validators.required, TextValidators.notEmpty],],
      
        //SECTION -3
        pm_carriername:['',[Validators.required, TextValidators.notEmpty],],
        pm_policynumber:['',[Validators.required, TextValidators.notEmpty],],
        pm_expirationdate:['',[Validators.required, TextValidators.notEmpty],],
        pm_amountMin:['',[Validators.required, TextValidators.notEmpty],],
        pm_amountMax:['',[Validators.required, TextValidators.notEmpty],],

        //SECTION -4
        office_Id:[''],
        ddloffice_Type: new FormControl({value: 'Primary', disabled: true}),        
        office_Phone: ['', [Validators.required, TextValidators.notEmpty,  Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)]],
        office_Fax: ['', [Validators.required, TextValidators.notEmpty, Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)]],
        office_ToHour: [''],          
        office_FromHour: [''],          
        office_Address1: ['',[Validators.required, TextValidators.notEmpty],],   
        office_Address2: ['',],      
        office_City: ['',[Validators.required, TextValidators.notEmpty],],      
        office_State: ['',[Validators.required, TextValidators.notEmpty],],      
        office_Zip: ['',[Validators.required, TextValidators.notEmpty],],  
        
        //SECTION -5
        specialty_Id:[''],       
        specialty_ProviderSpecialty: ['',[Validators.required, SpecialCharacterValidators.noSpecialCharacter, TextValidators.notEmpty],],
        specialty_EffectiveDate:['',[Validators.required, SpecialCharacterValidators.noSpecialCharacter, TextValidators.notEmpty],],
       
        //SECTION - 1C
        ddlpc_medicalEnrollmentStatus: new FormControl({ disabled: true}), 
        ddlpc_medicareEnrollmentStatus: new FormControl({ disabled: true}),
        ddlpc_panelStatus: new FormControl({ disabled: true}),
        pc_credentialingDate:[''],
      });

      
      this.disableFromControl("1a,1b,1c,3,4,5");  
      this.disableFormControlForFormGroup("2", this.boardSectionGroup);   
      //this.formGroup.controls["ddlpi_gender"].setValidators([Validators.required,TextValidators.notEmpty]);
      this.formGroup.controls["ddlpc_medicareEnrollmentStatus"].setValidators([Validators.required,TextValidators.notEmpty]);
      this.formGroup.controls["ddlpc_medicalEnrollmentStatus"].setValidators([Validators.required,TextValidators.notEmpty]);
      this.formGroup.controls["ddlpc_panelStatus"].setValidators([Validators.required,TextValidators.notEmpty]);      
      this.formGroup.controls["ddloffice_Type"]?.setValidators([Validators.required,TextValidators.notEmpty]);
      this.boardSectionGroup.controls["ddlpb_boardCertificationStatus"]?.setValue('');
     }
    
     onChanges(): void {      
      this.boardSectionGroup.controls["pb_boardname"].valueChanges.subscribe(val => {                  
        this.boardNameChangeEnableDisable(val);      
      });
      
      this.boardSectionGroup.controls["ddlpb_boardCertificationStatus"].valueChanges.subscribe(val => {          
        this.boardStatusChangeEnableDisable(val);                            
      });
     }


  private boardStatusChangeEnableDisable(val: any) {
    var effectivedate = this.boardSectionGroup.get('pb_effectivedate');
    var expirationDate = this.boardSectionGroup.get('pb_expirationdate');
    if (val == "Lifetime") {
      effectivedate?.setValidators([Validators.required]);
      expirationDate?.removeValidators([Validators.required]);
      effectivedate?.updateValueAndValidity();
      expirationDate?.updateValueAndValidity();
      if (this.isProviderBoardTrainingSectionEditable) {
        effectivedate?.enable();
        expirationDate?.enable();
      }
    } else if (val == "Not Certified") {      
      effectivedate?.removeValidators([Validators.required]);
      expirationDate?.removeValidators([Validators.required]);
      effectivedate?.updateValueAndValidity();
      expirationDate?.updateValueAndValidity();
      effectivedate?.setValue("");
      expirationDate?.setValue("");
      effectivedate?.disable();
      expirationDate?.disable();
    } else if (val == "Certified") {
      effectivedate?.setValidators([Validators.required]);
      effectivedate?.updateValueAndValidity();
      expirationDate?.setValidators([Validators.required]);
      expirationDate?.updateValueAndValidity();
      if (this.isProviderBoardTrainingSectionEditable) {
        effectivedate?.enable();
        expirationDate?.enable();
      }
    }
  }

  private boardNameChangeEnableDisable(val: any) {
    var boardStatus = this.boardSectionGroup.get('ddlpb_boardCertificationStatus');
    var effectivedate = this.boardSectionGroup.get('pb_effectivedate');
    var expirationDate = this.boardSectionGroup.get('pb_expirationdate');

    if (val) {      
      boardStatus?.setValidators([Validators.required]);
      effectivedate?.setValidators([Validators.required]);
      expirationDate?.setValidators([Validators.required]);
      if (this.isProviderBoardTrainingSectionEditable) {
        boardStatus?.enable();
        boardStatus?.markAsTouched();
        effectivedate?.enable();
        effectivedate?.markAsTouched();
        expirationDate?.enable();
        expirationDate?.markAsTouched();
      }
    }
    else {
      boardStatus?.setValue(null);
      boardStatus?.disable();
      effectivedate?.disable();
      effectivedate?.setValue("");
      expirationDate?.disable();
      expirationDate?.setValue("");
      boardStatus?.removeValidators([Validators.required]);
      effectivedate?.removeValidators([Validators.required]);
      expirationDate?.removeValidators([Validators.required]);
    }
    boardStatus?.updateValueAndValidity();
    effectivedate?.updateValueAndValidity();
    expirationDate?.updateValueAndValidity();
  }

     getConstants(){
      //set the provider information here
      this.providerAttestationService.getConstants().subscribe({
        next: async (response :IConstant[]) => {
          //console.log(response);
           this.constants$ = response;
           this.panelStatus = await this.constants$.filter(s => s.constantKey=='PanelStatus');
           this.medicalStatus = await this.constants$.filter(s => s.constantKey=='MedicalStatus');
           this.medicareStatus = await this.constants$.filter(s => s.constantKey=='MedicareStatus');
           this.boardStatus = await this.constants$.filter(s => s.constantKey=='BoardStatus');
           this.gender = await this.constants$.filter(s => s.constantKey=='Gender');           
        },
        error: (e) => {
          //console.error('Get constant request failed with error - ' + e);
          this.isLoading=false;
        },
        complete: () => {
         // console.info('Get constant request completed !!!') 
          this.isLoading=true;
        }
      });
     }

     getProviderData(isProviderDataOnly:boolean){
      //set the provider information here
      this.providerAttestationService.getProviderDetailsProvider(this.currentStorageService.SelectedProviderId).subscribe({
          next: async (response :IProviderViewModel) => {
            //console.log(response);
            this.isLoading=false;
            if(isProviderDataOnly==false)
            {
              await this.getproviderAttestationData(response); 
              await this.setAttestationPeriod();
            }              
            else
              this.providerData$ = response;      
            await this.setSection123FormField("1a,1b,1c,2,3"); 
            await this.setBoardTraining();           
          },
          error: (e) => {
            console.error('Provider details request failed with error - ' + e);
            this.isLoading=false;
          },
          complete: () => {
            console.info('Request completed !!!') 
            this.isLoading=true;
          }
        });
     }

     setBoardTraining() {
        this.boardSectionGroup.get("pb_boardname")?.setValue(this.providerData$.boardDetails[0]?.boardName);
        this.boardSectionGroup.get("ddlpb_boardCertificationStatus")?.setValue(this.providerData$.boardDetails[0]?.boardStatus);

        if(this.providerData$.boardDetails[0]?.boardStatus == "Not Certified"){                            
          this.boardSectionGroup.get("pb_effectivedate")?.setValue("");
          this.boardSectionGroup.get("pb_expirationdate")?.setValue("");
        }        
        else {
          let effectiveDate = moment(new Date(this.providerData$.boardDetails[0]?.effectiveDate ?? ""));     
          this.boardSectionGroup.get("pb_effectivedate")?.setValue(effectiveDate.isValid() ? effectiveDate.format("MM/DD/YYYY") : "");
          let expirationDate = moment(new Date(this.providerData$.boardDetails[0]?.expirationDate ?? ""));
          this.boardSectionGroup.get("pb_expirationdate")?.setValue(expirationDate.isValid() ? expirationDate.format("MM/DD/YYYY") : "");   
        }
     }

     getproviderAttestationData(providerData:IProviderViewModel){
      //get the provider attestation details here
      this.providerData$ = providerData; 
      this.providerAttestationService.getProviderAttestationByProviderID(this.providerData$.providerDetails.providerId).subscribe({
        next: (response :IProviderAttestationModel) => {
          //console.log(response);
          this.providerAttestationData$ = response;
          this.isReadOnly=  this.providerAttestationData$ != null;   
        },
        error: (error:any) => {
          console.error('Provider attestation details request failed with error - ' + error);
        },
        complete: () => {          
          console.info('Provider attestation details request completed !!!')           
        }
      });
     }

     setAttestationPeriod()
     {
      //attestionPeriod
      if (this.providerData$.providerDetails!=null && this.providerData$.providerDetails != undefined)
      {
        if(this.providerData$.providerDetails.attestationPeriod != null && this.providerData$.providerDetails.attestationPeriod !=undefined && this.providerData$.providerDetails.attestationPeriod.length!=0)
          this.attestionPeriod = 'Attestation Period ' + this.providerData$.providerDetails.attestationPeriod?.substring(4,this.providerData$.providerDetails.attestationPeriod.length +1).replace('0','Q') + ' ' + this.providerData$.providerDetails.attestationPeriod?.substring(0,4);            
      }
      //console.log(this.attestionPeriod);
     }

     setSection123FormField(frmSections:string)
     {
      let providerInfoData: IProviderModel;            
      let property: keyof typeof this.providerData$; 
      
      for (property in this.providerData$) {
        switch (property)
        {
          case "providerDetails":
                this.populateSection123FormField(this.providerData$,frmSections);
            break;
          case "locationDetails":
            this.providerOfficeInformation$ = this.providerData$.locationDetails;
            break;
          case "specialtyDetails":
            this.providerSpecialty$ = this.providerData$.specialtyDetails;
            break;  
        }
        //console.log(`${property}: ${this.providerData$[property]}`);            
      }
     }

    populateSection123FormField (data:IProviderViewModel,sectionId:string)
     {
      let providerInfoData: IProviderModel;         
      let propertyprovkey: keyof typeof providerInfoData;  
      
      //Section- 2 - Board Training
      let providerBoardData: IProviderBoard;         
      let propertyBoardkey: keyof typeof providerBoardData; 
      
      //Section- 3 - Provider Insurance 
      let providerInsuranceData: IProviderInsurance;         
      let propertyInsurancekey: keyof typeof providerInsuranceData; 

        let sections:string[] = sectionId.split(',');
        sections.forEach( (section) => {
            let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
            for (var i = 0; i < filteredFormFields.length; i++) {
              if (filteredFormFields[i].dataKey !=null && filteredFormFields[i].dataKey!=undefined && filteredFormFields[i].dataKey!='')
              {
                if(this.providerData$.providerDetails !=null && this.providerData$.providerDetails!=undefined)
                {
                  switch (section)
                  {
                    case "1a":
                    case "1b":
                    case "1c":
                      //SECTION 1 - provider Info section
                      for (propertyprovkey in this.providerData$.providerDetails) 
                      {
                        if (filteredFormFields[i].dataKey == propertyprovkey)
                        {
                          if (filteredFormFields[i].fieldType =='text') 
                          {
                            if (filteredFormFields[i].isRange) 
                            {                              
                              if(filteredFormFields[i].fieldName.toLowerCase().includes('min'))
                                  this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(CommonUtil.getParseData(this.providerData$.providerDetails[propertyprovkey],true));
                                if(filteredFormFields[i].fieldName.toLowerCase().includes('max'))
                                  this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(CommonUtil.getParseData(this.providerData$.providerDetails[propertyprovkey],false));
                            }                          
                            else
                              this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(String(this.providerData$.providerDetails[propertyprovkey]).trim());
                          }
                          if (filteredFormFields[i].fieldType =='dropdown')
                          {                            
                            if(filteredFormFields[i].fieldName.toLowerCase().endsWith('status'))
                            {
                                if(this.isReadOnly)
                                {
                                  let status = this.medicareStatus.find( s => s.constantValue == this.setStatusValue(String(this.providerData$.providerDetails?.[propertyprovkey])));
                                  if (filteredFormFields[i].fieldName.toLowerCase().includes("medicare"))
                                    this.providerMedicareStatusToDisplay =(status !=null && status!=undefined) ?  status.constantDesc : '';
                        
                                  status = this.medicalStatus.find( s => s.constantValue == this.setStatusValue(String(this.providerData$.providerDetails?.[propertyprovkey])));
                                  if (filteredFormFields[i].fieldName.toLowerCase().includes("medical"))
                                  this.providerMedicalStatusToDisplay =(status !=null && status!=undefined) ?  status.constantDesc : '';
                        
                                  status = this.panelStatus.find( s => s.constantValue == this.setStatusValue(String(this.providerData$.providerDetails?.[propertyprovkey])));
                                  if (filteredFormFields[i].fieldName.toLowerCase().includes("panel"))
                                  this.providerPanelStatusToDisplay =(status !=null && status!=undefined) ?  status.constantDesc : '';
                                  
                                  if (filteredFormFields[i].fieldName.toLowerCase().includes("certification"))
                                  {
                                    let certification = this.boardStatus.find( c => (c.constantValue) == this.providerData$.providerDetails?.[propertyprovkey])
                                    this.providerCertificationToDisplay = ((certification !=null && certification!=undefined) ? certification.constantDesc : '');
                                  }                              
                                }
                                else 
                                {
                                  this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(this.setStatusValue(String(this.providerData$.providerDetails[propertyprovkey])));
                                }
                            }
                            else
                            {
                              this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(this.providerData$.providerDetails[propertyprovkey]!='' ? String(this.providerData$.providerDetails[propertyprovkey]).trim() :'');                            
                            }    
                          } 
                          if (filteredFormFields[i].fieldType =='checkbox')
                          {
                            this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(this.providerData$.providerDetails[propertyprovkey]);
                          } 
                          if (filteredFormFields[i].fieldType =='date') 
                          {
                            let dt = moment(new Date(String(this.providerData$.providerDetails[propertyprovkey]) ?? ""));     
                            //this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(effectiveDate.isValid() ? effectiveDate.format("MM/DD/YYYY") : "");
                            this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(dt.isValid() ? dt.format('MM/DD/YYYY') : "");
                          }                  
                        } 
                      } 
                      break;
                    case"2":                               
                      break;
                    case "3":
                      //Section 3 - Liability and MalPractice section
                      this.providerInsurance$ = this.providerData$.insuranceDetails;
                      if( this.providerInsurance$ !=null && this.providerInsurance$ != undefined)
                      {
                        if (this.providerInsurance$.length > 0)
                        {
                          //sort the data by created date desc and take top 1
                          this.providerInsurance$.sort((a, b) => ( a.createdDate! > b.createdDate! ? -1 : 1 ));
                          for (propertyInsurancekey in this.providerInsurance$[0]) 
                          {
                            if (filteredFormFields[i].dataKey == propertyInsurancekey)
                            {
                              if (filteredFormFields[i].fieldType =='text') 
                              {
                                if (filteredFormFields[i].isRange) 
                                {
                                  if(filteredFormFields[i].fieldName.toLowerCase().includes('min'))
                                      this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(CommonUtil.getParseData(this.providerInsurance$[0][propertyInsurancekey],true));
                                    if(filteredFormFields[i].fieldName.toLowerCase().includes('max'))
                                      this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(CommonUtil.getParseData(this.providerInsurance$[0][propertyInsurancekey],false));
                                }                          
                                else
                                  this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(this.providerInsurance$[0][propertyInsurancekey]);
                              }
                              if (filteredFormFields[i].fieldType =='date') 
                              {
                                this.formGroup.get(filteredFormFields[i].fieldName)?.setValue(moment(String(this.providerInsurance$[0][propertyInsurancekey])).format('MM/DD/YYYY'));
                              }
                            }
                          }
                        }                      
                        break;
                      } 
                    }
                  }
                }
                else
                {
                  //for now set form field blank
                  //this.formGroup.get(filteredFormFields[i].fieldName)?.setValue('');
                }
              }
            });  //form sections loop    
          }
      
      setProviderData(frmSection:string){
        //SECTION 1a,1b,1c
        let providerInfoData: IProviderModel;  
        let propertyProvkey: keyof typeof providerInfoData;  
        
        //SECTION 4
        let officeInfoData: IProviderOfficeInformationModel;
        let propertyOfficeInfokey: keyof typeof officeInfoData; 

        //Section- 2 - Board Training
        let providerBoardData: IProviderBoard;         
        let propertyBoardkey: keyof typeof providerBoardData; 

        //Section- 3 - Provider Insurance 
        let providerInsuranceData: IProviderInsurance;         
        let propertyInsurancekey: keyof typeof providerInsuranceData; 

        let sections:string[] = frmSection.split(',');
        let providerOfficeInformationData :IProviderOfficeInformationModel={};
        sections.forEach( (section) => {
            let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
            for (var i = 0; i < filteredFormFields.length; i++) {
              if (filteredFormFields[i].dataKey !=null && filteredFormFields[i].dataKey!=undefined && filteredFormFields[i].dataKey!='')
              {
                switch (section)
                {
                  case "1a":
                  case "1b":
                  case "1c":                 
                    {
                      if(this.providerData$.providerDetails !=null && this.providerData$.providerDetails!=undefined)
                      {
                        for (propertyProvkey in this.providerData$.providerDetails) 
                        {
                          if (filteredFormFields[i].dataKey == propertyProvkey)
                          {
                            //dynamically assigning this.providerData$.providerDetails properties
                            if(filteredFormFields[i].isRange)
                            {                              
                              this.setProviderObjectData(this.formGroup.get('pi_ageMin')?.value + '-' + this.formGroup.get('pi_ageMax')?.value,this.providerData$.providerDetails,propertyProvkey)
                            }
                            else if(filteredFormFields[i].fieldType=='date')
                            {          
                              if(filteredFormFields[i].isRequired)                    
                                this.setProviderObjectData(moment(CommonUtil.parseDate(this.formGroup.get(filteredFormFields[i].fieldName)?.value)).format('YYYY-MM-DD'),this.providerData$.providerDetails,propertyProvkey)
                              else
                              {
                                this.setProviderObjectData(this.formGroup.get(filteredFormFields[i].fieldName)?.value!='' ? moment(CommonUtil.parseDate(this.formGroup.get(filteredFormFields[i].fieldName)?.value)).format('YYYY-MM-DD'):'',this.providerData$.providerDetails,propertyProvkey)
                              }
                            }
                            else if(filteredFormFields[i].fieldType=='dropdown' && filteredFormFields[i].fieldName.toLocaleLowerCase().includes('status'))
                            {
                              this.setProviderObjectData(this.formGroup.get(filteredFormFields[i].fieldName)?.value=='0'?false : true,this.providerData$.providerDetails,propertyProvkey)
                            }
                            else
                            {
                              this.setProviderObjectData(this.formGroup.get(filteredFormFields[i].fieldName)?.value,this.providerData$.providerDetails,propertyProvkey)
                            }
                          }
                        }
                      }
                      break;
                    } 
                    case "2":
                    { 
                      //board training
                      if( this.providerBoard !=null && this.providerBoard != undefined)
                        {
                          for (propertyBoardkey in this.providerBoard) 
                          {
                              if (filteredFormFields[i].dataKey == propertyBoardkey)
                              {                                  
                                if(filteredFormFields[i].fieldType=='date')
                                {
                                  this.setProviderBoardObjectData(moment(CommonUtil.parseDate(this.boardSectionGroup.get(filteredFormFields[i].fieldName)?.value)).format('YYYY-MM-DD'),this.providerBoard,propertyBoardkey)
                                }                                  
                                else
                                {
                                  this.setProviderBoardObjectData(this.boardSectionGroup.get(filteredFormFields[i].fieldName)?.value,this.providerBoard,propertyBoardkey)
                                }                                 
                              }
                          }
                        }
                      break;                        
                    }
                    case "3":
                    {
                      //Provider liability
                      if( this.providerInsurance$ !=null && this.providerInsurance$ != undefined)
                      {
                        if (this.providerInsurance$.length > 0)
                        {
                          //sort the data by created date desc and take top 1
                          this.providerInsurance$.sort((a, b) => ( a.createdDate! > b.createdDate! ? -1 : 1 ));
                          for (propertyInsurancekey in this.providerInsurance$[0]) 
                          {
                            if (filteredFormFields[i].dataKey == propertyInsurancekey)
                            {
                              if(filteredFormFields[i].isRange)
                              {                              
                                this.setProviderInsuranceObjectData(this.formGroup.get('pm_amountMin')?.value + '-' + this.formGroup.get('pm_amountMax')?.value,this.providerInsurance$[0],propertyInsurancekey)
                              }
                              else if(filteredFormFields[i].fieldType=='date')
                              {
                                this.setProviderInsuranceObjectData(moment(CommonUtil.parseDate(this.formGroup.get(filteredFormFields[i].fieldName)?.value)).format('YYYY-MM-DD'),this.providerInsurance$[0],propertyInsurancekey)
                              }                                  
                              else
                              {
                                this.setProviderInsuranceObjectData(this.formGroup.get(filteredFormFields[i].fieldName)?.value,this.providerInsurance$[0],propertyInsurancekey)
                              }                                 
                            }
                          }
                        }
                      }
                      break;
                    }                  
                    case "4":
                      {                   
                        //office information     
                        if(this.providerOfficeInformation$ !=null && this.providerOfficeInformation$!=undefined){
                          let index = this.providerOfficeInformation$.findIndex((obj => obj.locationId == this.selectedLocationId));
                          if (index != -1)
                          {
                            providerOfficeInformationData = this.providerOfficeInformation$[index];
                            for (propertyOfficeInfokey in this.providerOfficeInformation$[index]) 
                            {                   
                              if (filteredFormFields[i].dataKey == propertyOfficeInfokey)
                              {   
                                if (filteredFormFields[i].fieldName.includes('hrs'))
                                {
                                  //set office hours only if address tye is not billing
                                  if (this.formGroup.get('ddloffice_Type')?.value!='Billing')
                                  {
                                    //code to set office hours here
                                    if(this.addOfficeHourComponent.officeHours!=null && this.addOfficeHourComponent.officeHours!=undefined)
                                    {
                                      if(this.addOfficeHourComponent.officeHours.length>0)
                                      {
                                        var idx = this.addOfficeHourComponent.officeHours.findIndex( o => o.key ==filteredFormFields[i].fieldName);
                                        if (idx != -1)
                                        {
                                          var filteredOfficeHrs = this.addOfficeHourComponent.officeHours[idx];
                                          if ((this.isOfficeClosed(filteredFormFields[i].fieldName) ==false && filteredOfficeHrs.min !=null && filteredOfficeHrs.min !=undefined && filteredOfficeHrs.min !='') && ( filteredOfficeHrs.max !=null && filteredOfficeHrs.max !=undefined && filteredOfficeHrs.max !=''))
                                          {
                                            //console.log(filteredOfficeHrs);
                                            this.setLocationObjectData(filteredOfficeHrs.min + '-' + filteredOfficeHrs.max,this.providerOfficeInformation$[index],propertyOfficeInfokey);
                                          }
                                          else
                                          {
                                            this.setLocationObjectData(this.isOfficeClosed(filteredFormFields[i].fieldName) ? 'Closed' : '',this.providerOfficeInformation$[index],propertyOfficeInfokey);                                            
                                          }
                                        }
                                      }
                                    }
                                  }
                                  else
                                  {
                                    //Set blank value for office hours if address type is billing
                                    this.setLocationObjectData('',this.providerOfficeInformation$[index],propertyOfficeInfokey);
                                  }                                  
                                }
                                else
                                {                                  
                                  //dynamically assigning this.providerData$.locationDetails properties
                                  this.setLocationObjectData(this.formGroup.get(filteredFormFields[i].fieldName)?.value,this.providerOfficeInformation$[index],propertyOfficeInfokey)
                                }
                                break;                                                            
                              }
                            }
                            if(i==(filteredFormFields.length-1)){
                              //update office inforamtion here. after the field loop is over.                              
                              //validate office hours here                             
                              if(this.ValidateOfficeHours(providerOfficeInformationData))
                                this.updateProviderOfficeInformation(providerOfficeInformationData,true);
                            }

                          }                          
                        }
                        break;  
                    }                      
                  }
                }
              }               
          });
          return true;
      }

      setProviderObjectData< OBJ extends IProviderModel, KEY extends keyof OBJ, VAL extends OBJ[KEY]>(value: VAL, obj: OBJ, prop: KEY) {
        obj[prop] = value;
      }
      
      setLocationObjectData< OBJ extends IProviderOfficeInformationModel, KEY extends keyof OBJ, VAL extends OBJ[KEY]>(value: VAL, obj: OBJ, prop: KEY) {
        obj[prop] = value;
      }

      setProviderInsuranceObjectData< OBJ extends IProviderInsurance, KEY extends keyof OBJ, VAL extends OBJ[KEY]>(value: VAL, obj: OBJ, prop: KEY) {
        obj[prop] = value;
      }

      setProviderBoardObjectData< OBJ extends IProviderBoard, KEY extends keyof OBJ, VAL extends OBJ[KEY]>(value: VAL, obj: OBJ, prop: KEY) {
        obj[prop] = value;
      }

    onChangeBoardCertificationStatus(value: any)
    {
        this.selectedBoardCertificationStatus = (value.target.value=='Active')? true:false;      
        //this.validateBoardSection();
        //console.log(this.selectedBoardCertificationStatus);
    }

    onChangeMedicareEnrollmentStatus(value: any)
    {
        this.SelectedMedicareEnrollmentStatus = (value.target.value=='Active')? true:false;
       // console.log(this.SelectedMedicareEnrollmentStatus);
    }

    onChangeMedicalEnrollmentStatus(value: any)
    {
        this.SelectedMedicalEnrollmentStatus = (value.target.value=='Active')? true:false;
       // console.log(this.SelectedMedicalEnrollmentStatus);
    }
    
    onChangePanelStatus(value: any)
    {
        this.SelectedPanelStatus = (value.target.value=='Active')? true:false;
        //console.log(this.SelectedPanelStatus);
    }

    onChangeOfficeType(value:any){
      this.SelectedOfficeType = value.target.value;

      //console.log(this.SelectedOfficeType);
    }

    onChangetoOfficeHours(value:any){
      //console.log( value.target.value);
    }

    onChangefromOfficeHours(value:any){
     // console.log( value.target.value);
    }
   
    onChangeGender(value:any){
    } 
    
    setStatusValue(value:string){   
      if (value =='')
        return '';
      else if (value == 'false')
        return '0'
      else if (value == 'true')
        return '1'
      else
        return value;
    }

    getStatusValue(value?:string):boolean{         
      if (value =='')
        return false;
      else 
        return (value=='1');          
    }

    attestationCheck(event:any){
      if(this.btnAttest !=null && this.btnAttest!=undefined){         
          this.btnAttest.nativeElement.disabled = !event.target.checked
          if(event.target.checked == false)
          this.attestationErrorMessage ='';
          //console.log(event.target.checked);
      }      
    }
    
    checkPCP(event:any){

    }

    checkSpecailty(event:any){
      
    }
    /*****************************SECTION 1A - PROVIDER INFORMATION SECTION *************************************/
    editProviderInformation(): void{      
      this.enableFromControl('1a');
      this.isEditable = true;      
      this.formGroup.get("ddlpi_gender")?.disable();
    }     

    saveProviderInformation(section:string):void {    
     if (this.ValidateForm(section,false))
     {
        if (this.setProviderData(section))
        {
          //console.log(this.providerData$.providerDetails);
          this.providerData$.providerDetails.updatedBy=this.currentStorageService.userName;
          this.providerData$.providerDetails.updatedDate = moment().format('YYYY-MM-DDTHH:mm:ss');   
          if(this.providerData$.providerDetails.credentialingDate == '' || this.providerData$.providerDetails.credentialingDate == 'Invalid date')
            this.providerData$.providerDetails.credentialingDate = null;
              
          this.providerAttestationService.updateProvider(this.providerData$.providerDetails).subscribe({
            next: async(response :IProviderModel) => {
              await this.getProviderData(true);
              this.disableFromControl(section);
               if(section=='1a') this.isEditable=false;
               if(section=='1b') this.isProviderLicenseSectionEditable = false;
               
               //console.log(this.isProviderContractInfoSectionEditable);
               
               if(section=='1c') this.isProviderContractInfoSectionEditable = false;               
            },
            error: (e) => {
              if(section=='1a')
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_INFORMATION_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR)
              if(section=='1b')
              this.toast.openSnackBar(CONSTANT.MSG_LICENSE_INFORMATION_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR)
              if(section=='1c')
              this.toast.openSnackBar(CONSTANT.MSG_CONTRACT_INFORMATION_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR)    
            },
            complete: () => {
              //console.info('Provider Infomation updated !!!') 
              if(section=='1a')
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_INFORMATION_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS)
              if(section=='1b')
              this.toast.openSnackBar(CONSTANT.MSG_LICENSE_INFORMATION_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS)
              if(section=='1c')
              this.toast.openSnackBar(CONSTANT.MSG_CONTRACT_INFORMATION_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS)
            }
          });       
        }          
      }       
    }

    cancelProviderInformation(): void{     
      this.setSection123FormField("1a");
      this.disableFromControl('1a');
      this.isEditable=false;
    }
    /*****************************SECTION 1A - PROVIDER INFORMATION SECTION ENDS HERE*************************************/

    /*****************************SECTION 1B - PROVIDER LICENSE SECTION *************************************/
    editProviderLicense():void{
      //SECTION -2
      this.enableFromControl('1b');
      this.isProviderLicenseSectionEditable = true;

    }

    // saveProviderLicense():void{
    //   //SECTION -2
    //   if (this.ValidateForm('1b',false)) {
    //   this.isProviderLicenseSectionEditable = false;
    //   this.disableFromControl('1b');
    //   }
    // }

    cancelProviderLicense():void{
      //SECTION -2
      this.setSection123FormField("1b");
      this.disableFromControl('1b');
      this.isProviderLicenseSectionEditable = false;

    }
    /*****************************SECTION 1B - PROVIDER LICENSE SECTION ENDS HERE*************************************/

    /*****************************SECTION 2 - BOARD TRAINING SECTION *************************************/
    editProviderBoardTraining():void{
      this.isProviderBoardTrainingSectionEditable = true;
      this.boardSectionGroup.get('pb_boardname')?.enable();
      //SECTION -2
      if(this.boardSectionGroup.get('pb_boardname')?.value){
        this.boardNameChangeEnableDisable(this.boardSectionGroup.get('pb_boardname')?.value);
        //this.enableFormControlForFormGroup("2", this.boardSectionGroup);
      }
      else {        
        this.boardSectionGroup.get('ddlpb_boardCertificationStatus')?.disable();
        this.boardSectionGroup.get('pb_effectivedate')?.disable();
        this.boardSectionGroup.get('pb_expirationdate')?.disable();
      }
      
      //formGroup.get(filteredFormFields[i].fieldName)?.enable();
        //  if(formGroup.get(filteredFormFields[i].fieldName)?.hasError('empty'))
          //  formGroup.get(filteredFormFields[i].fieldName)?.markAsUntouched();          
      

    }
  


    saveProviderBoardTraining():void{
      //SECTION -2
      //debugger;

      if(!this.boardSectionGroup.valid)
        return;
      if(!this.boardExpirationDateIsValid())
        return;
     
      const providerBoard: IProviderBoard = {
        updatedBy: this.currentStorageService?.userName ?? "",  
        createdBy: this.currentStorageService?.userName ?? "",
        updatedDate: moment.utc(new Date).format(),
        boardName: this.boardSectionGroup.get('pb_boardname')?.value,
        boardStatus: this.boardSectionGroup.get('ddlpb_boardCertificationStatus')?.value,
        effectiveDate: !this.boardSectionGroup.get('pb_effectivedate')?.value || this.boardSectionGroup.get('pb_effectivedate')?.value.trim() == "" ? undefined : moment(CommonUtil.parseDate(this.boardSectionGroup.get('pb_effectivedate')?.value)).format('YYYY-MM-DD'),
        expirationDate: this.boardSectionGroup.get('pb_expirationdate')?.value.trim() == "" ? undefined : moment(CommonUtil.parseDate(this.boardSectionGroup.get('pb_expirationdate')?.value)).format('YYYY-MM-DD'),      
        providerId: this.providerData$.providerDetails.providerId,        
        createdDate: this.providerData$.boardDetails[0]?.createdDate,
        isDeleted: false,
        boardId: this.providerData$.boardDetails[0]?.boardId
      }

      if(this.boardSectionGroup.get('pb_boardname')?.value == null || this.boardSectionGroup.get('pb_boardname')?.value == "")
          providerBoard.isDeleted = true;
        
      if (providerBoard.boardId != undefined && providerBoard.boardId > 0) {
        this.providerAttestationService.updateProviderBoard(providerBoard).subscribe({
          next: async(response :IProviderBoard) => {
            await this.getProviderData(true);            
            this.isProviderBoardTrainingSectionEditable = false;
            this.disableFormControlForFormGroup('2', this.boardSectionGroup);            
          },
          error: (e) => {
            this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_BOARD_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
          },
          complete: () => {
            this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_BOARD_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
          }
        });   
      } else {
        this.providerAttestationService.addProviderBoard(providerBoard).subscribe({
          next: async(response :IProviderBoard) => {
            await this.getProviderData(true);            
            this.isProviderBoardTrainingSectionEditable = false;
            this.disableFormControlForFormGroup('2', this.boardSectionGroup);            
          },
          error: (e) => {
            this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_BOARD_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
          },
          complete: () => {
            this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_BOARD_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
          }
        });   
      }
    }
    
    
    boardExpirationDateIsValid(): boolean{
      //var effectiveDateControl = this.boardSectionGroup.get('pb_effectivedate');
      let effectiveDate = moment(CommonUtil.parseDate(this.boardSectionGroup.get('pb_effectivedate')?.value), 'MM/DD/YYYY');
      let expirationDate = moment(CommonUtil.parseDate(this.boardSectionGroup.get('pb_expirationdate')?.value), 'MM/DD/YYYY');             
      if (this.boardSectionGroup.get('pb_effectivedate')?.value?.length >=8 && this.boardSectionGroup.get('pb_expirationdate')?.value.length >=8)
      {                   
            if ((expirationDate.diff(effectiveDate, 'days') < 0))
            {
              return false;
            }                            
      }
      return true;
    }

    cancelProviderBoardTraining():void{
      //SECTION -2
      this.setBoardTraining();
      this.disableFormControlForFormGroup('2', this.boardSectionGroup);
      this.isProviderBoardTrainingSectionEditable = false;
    }
    /*****************************SECTION 2 - BOARD TRAINING SECTION ENDS HERE*************************************/

    /*****************************SECTION 3 - PROVIDER LIABILITY AND MALPRACTICE SECTION *************************************/
    editProviderLiability():void{
      //SECTION -3
      this.enableFromControl('3');
      this.isProviderLiabilitySectionEditable = true;
    }

    saveProviderInsurance():void{
      if (this.ValidateForm('3',false)) {
        if (this.setProviderData('3'))
        {
          //console.log(this.providerInsurance$);
          if( this.providerInsurance$ !=null && this.providerInsurance$ != undefined)
            {
              if (this.providerInsurance$.length > 0)
              {
                //edit/update provider Insurance
                //sort the data by created date desc and take top 1
                this.providerInsurance$.sort((a, b) => ( a.createdDate! > b.createdDate! ? -1 : 1 ));      
                this.providerInsurance$[0].updatedBy=this.currentStorageService.userName; 
                this.providerInsurance$[0].updatedDate = moment().format('YYYY-MM-DDTHH:mm:ss');
                this.providerAttestationService.updateProviderInsurance(this.providerInsurance$[0]).subscribe({
                  next: async(response :IProviderInsurance) => {
                    await this.getProviderData(true);
                    this.disableFromControl('3');
                   this.isProviderLiabilitySectionEditable = false;
                  },
                  error: (e) => {
                    this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_INSURANCE_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
                  },
                  complete: () => {
                    this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_INSURANCE_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
                  }
              });
            }
            else
            {
              //add provider Insurance
              let providerInsuranceData: IProviderInsurance;
              providerInsuranceData ={
                isDeleted: false,
                createdDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                updatedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                createdBy: this.currentStorageService.userName,
                updatedBy: this.currentStorageService.userName,
                providerInsuranceId: 0,
                providerId: this.providerData$.providerDetails.providerId,
                carrierName: this.formGroup.get('pm_carriername')?.value,
                policyNo: this.formGroup.get('pm_policynumber')?.value,
                expirationDate:  moment(CommonUtil.parseDate(this.formGroup.get('pm_expirationdate')?.value)).format('YYYY-MM-DD'),
                amount: this.formGroup.get('pm_amountMin')?.value + '-' + this.formGroup.get('pm_amountMax')?.value
              }
              //console.log(providerInsuranceData);
              this.providerData$.insuranceDetails.push(providerInsuranceData);
              this.providerAttestationService.addProviderInsurance(providerInsuranceData).subscribe({
                next: async(response :IProviderInsurance) => {
                  await this.getProviderData(true);
                  this.disableFromControl('3');          
                  this.isProviderLiabilitySectionEditable =false; 
                  this.providerLiabilityErrorMessage="";
                  },
                  error: (e) => {
                    this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_INSURANCE_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR)
                  },
                  complete: () => {
                    this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_INSURANCE_ADD,'OK',ENUM.MESSAGE_TYPE.SUCCESS)
                  }
              });
            }
          }          
        }        
      }
    }

    cancelProviderLiability():void{
      this.setSection123FormField("3");
      this.disableFromControl('3');
      this.isProviderLiabilitySectionEditable = false;
      this.isValidAmountRange = true;
    }
    /*****************************SECTION 3 - PROVIDER LIABILTIY AND MALPRACTICE SECTION ENDS HERE *************************************/

    /*****************************SECTION 1C - CONTRACTION INFORMATION SECTION *************************************/
    editContractInformation():void{
      this.enableFromControl('1c');
      this.isProviderContractInfoSectionEditable = true;
    }

    saveContractInformation():void{
      if (this.ValidateForm('1c',false)) {
        this.disableFromControl('1c');
      this.isProviderContractInfoSectionEditable = false;
      }
      
    }
    cancelContractInformation():void{
      this.setSection123FormField("1c");
      this.disableFromControl('1c');
      this.isProviderContractInfoSectionEditable = false;
    }
    /*****************************SECTION 1C - CONTRACT INFORMATION SECTION ENDS HERE*************************************/

    /*****************************SECTION 4 - OFFICE INFORMATION SECTION *************************************/
    addOfficeInformation() :void {
      this.officeInformationErrorMessage="";
      this.enableFromControl('4');     
      this.ResetFromControl('4');
      this.isOfficeInformationSectionEditable=true;
      this.isOfficeInformationAddClick =true;
      //this.officeHoursFormAction='Add'
      this.addOfficeHourComponent.officeHoursFormAction='Add';
      this.addOfficeHourComponent.isMonClose =false;
      this.addOfficeHourComponent.isTueClose =false;
      this.addOfficeHourComponent.isWedClose =false;
      this.addOfficeHourComponent.isThuClose =false;
      this.addOfficeHourComponent.isFriClose =false;
      this.addOfficeHourComponent.isSatClose =false;
      this.addOfficeHourComponent.isSunClose =false;
      this.addOfficeHourComponent.formGroup.reset();
      //this.addOfficeHoursForm.reset();
      
      this.showReadonlyModal=false;
      this.modalwidth='600px';      
    }

    editOfficeInformation(id?:number):void{
      //console.log(id);
      this.officeInformationErrorMessage="";
      this.isOfficeInformationSectionEditable = true;
      this.isOfficeInformationAddClick=false;
      this.selectedLocationId = id;
      //this.officeHoursFormAction='Edit';
      this.showReadonlyModal=false;
      this.modalwidth='600px';

      this.addOfficeHourComponent.locations$ = this.providerOfficeInformation$;
      this.addOfficeHourComponent.selectedLocationId = id;
      this.addOfficeHourComponent.officeHoursFormAction ='Edit';
      this.addOfficeHourComponent.isMonToValue = false;
      this.addOfficeHourComponent.isSameAsMonday = false;

      if (this.providerOfficeInformation$ != null && this.providerOfficeInformation$!=undefined)
      {
        let index = this.providerOfficeInformation$.findIndex((obj => obj.locationId == id));
        if (index != -1)
        {         
          let providerOfficeInformationData = this.providerOfficeInformation$[index];        
          this.enableFromControl('4');
          //set provider attestation form value for office hours
          this.SelectedOfficeType = providerOfficeInformationData.locationType!;
          this.formGroup.get('office_Id')?.setValue(providerOfficeInformationData.locationId);
          this.formGroup.get('ddloffice_Type')?.setValue(providerOfficeInformationData.locationType);
          this.formGroup.get('office_Phone')?.setValue(providerOfficeInformationData.phone);
          this.formGroup.get('office_Fax')?.setValue(providerOfficeInformationData.fax);
          this.formGroup.get('office_ToHour')?.setValue(CommonUtil.getParseData(providerOfficeInformationData.monHours,true));
          this.formGroup.get('office_FromHour')?.setValue(CommonUtil.getParseData(providerOfficeInformationData.monHours,false));
          this.formGroup.get('office_Address1')?.setValue(providerOfficeInformationData.streetAddress1);      
          this.formGroup.get('office_Address2')?.setValue(providerOfficeInformationData.streetAddress2);  
          this.formGroup.get('office_City')?.setValue(providerOfficeInformationData.city);  
          this.formGroup.get('office_State')?.setValue(providerOfficeInformationData.state);  
          this.formGroup.get('office_Zip')?.setValue(providerOfficeInformationData.zip);  
          
          //set add office hours modal form value.
          this.addOfficeHourComponent.formGroup.get('office_MonToHour')?.setValue((providerOfficeInformationData.monHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.monHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_MonFromHour')?.setValue((providerOfficeInformationData.monHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.monHours,false):'00:00');          
          this.addOfficeHourComponent.isMonClose =(providerOfficeInformationData.monHours == 'Closed');
          let ctrl_chkMon = this.addOfficeHoursForm.get('chkoffice_Mon') as FormControl;
          ctrl_chkMon.patchValue(providerOfficeInformationData.monHours == 'Closed');
          
          this.addOfficeHourComponent.formGroup.get('office_TueToHour')?.setValue((providerOfficeInformationData.tuesHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.tuesHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_TueFromHour')?.setValue((providerOfficeInformationData.tuesHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.tuesHours,false):'00:00');
          this.addOfficeHourComponent.isTueClose =(providerOfficeInformationData.tuesHours == 'Closed');
          let ctrl_chkTue = this.addOfficeHoursForm.get('chkoffice_Tue') as FormControl;
          ctrl_chkTue.patchValue(providerOfficeInformationData.tuesHours == 'Closed');

          this.addOfficeHourComponent.formGroup.get('office_WedToHour')?.setValue((providerOfficeInformationData.wedHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.wedHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_WedFromHour')?.setValue((providerOfficeInformationData.wedHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.wedHours,false):'00:00');
          this.addOfficeHourComponent.isWedClose =(providerOfficeInformationData.wedHours == 'Closed');
          let ctrl_chkWed = this.addOfficeHoursForm.get('chkoffice_Wed') as FormControl;
          ctrl_chkWed.patchValue(providerOfficeInformationData.wedHours == 'Closed');

          this.addOfficeHourComponent.formGroup.get('office_ThuToHour')?.setValue((providerOfficeInformationData.thursHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.thursHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_ThuFromHour')?.setValue((providerOfficeInformationData.thursHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.thursHours,false):'00:00');
          this.addOfficeHourComponent.isThuClose =(providerOfficeInformationData.thursHours == 'Closed');
          let ctrl_chkThu = this.addOfficeHoursForm.get('chkoffice_Thu') as FormControl;
          ctrl_chkThu.patchValue(providerOfficeInformationData.thursHours == 'Closed');

          this.addOfficeHourComponent.formGroup.get('office_FriToHour')?.setValue((providerOfficeInformationData.friHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.friHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_FriFromHour')?.setValue((providerOfficeInformationData.friHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.friHours,false):'00:00');
          this.addOfficeHourComponent.isFriClose =(providerOfficeInformationData.friHours == 'Closed');
          let ctrl_chkFri = this.addOfficeHoursForm.get('chkoffice_Fri') as FormControl;
          ctrl_chkFri.patchValue(providerOfficeInformationData.friHours == 'Closed');

          this.addOfficeHourComponent.formGroup.get('office_SatToHour')?.setValue((providerOfficeInformationData.satHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.satHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_SatFromHour')?.setValue((providerOfficeInformationData.satHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.satHours,false):'00:00');
          this.addOfficeHourComponent.isSatClose =(providerOfficeInformationData.satHours == 'Closed');
          let ctrl_chkSat = this.addOfficeHoursForm.get('chkoffice_Sat') as FormControl;
          ctrl_chkSat.patchValue(providerOfficeInformationData.satHours == 'Closed');

          this.addOfficeHourComponent.formGroup.get('office_SunToHour')?.setValue((providerOfficeInformationData.sunHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.sunHours,true):'00:00');
          this.addOfficeHourComponent.formGroup.get('office_SunFromHour')?.setValue((providerOfficeInformationData.sunHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.sunHours,false):'00:00');
          this.addOfficeHourComponent.isSunClose =(providerOfficeInformationData.sunHours == 'Closed');
          let ctrl_chkSun = this.addOfficeHoursForm.get('chkoffice_Sun') as FormControl;
          ctrl_chkSun.patchValue(providerOfficeInformationData.sunHours == 'Closed');

          this.addOfficeHourComponent.officeHours=[
            { key:'officeMonhrs',min:CommonUtil.getParseData(providerOfficeInformationData.monHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.monHours,false)},
            { key:'officeTuehrs',min:CommonUtil.getParseData(providerOfficeInformationData.tuesHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.tuesHours,false)},
            { key:'officeWedhrs',min:CommonUtil.getParseData(providerOfficeInformationData.wedHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.wedHours,false)},
            { key:'officeThuhrs',min:CommonUtil.getParseData(providerOfficeInformationData.thursHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.thursHours,false)},
            { key:'officeFrihrs',min:CommonUtil.getParseData(providerOfficeInformationData.friHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.friHours,false)},
            { key:'officeSathrs',min:CommonUtil.getParseData(providerOfficeInformationData.satHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.satHours,false)},
            { key:'officeSunhrs',min:CommonUtil.getParseData(providerOfficeInformationData.sunHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.sunHours,false)},
          ];
          //console.log (this.addOfficeHourComponent.officeHours);
        }        
      } 
    }

    saveOfficeInformation():void{      
      this.officeInformationErrorMessage =='';
      if (this.ValidateForm('4',false)) 
      {

        const phoneFormat: RegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;  
        if(!phoneFormat.test(this.formGroup.get('office_Phone')?.value) || !phoneFormat.test(this.formGroup.get('office_Fax')?.value) )
          return;
        
        if(this.validateOfficeType())
        {
          if(this.isOfficeInformationSectionEditable==true){ 
            let providerOfficeInformationData: IProviderOfficeInformationModel={};       
            // Add office information
            if(this.formGroup.get('office_Id')?.value == '')
            {  
              if(this.providerOfficeInformation$ !=null && this.providerOfficeInformation$ !=undefined)
              {    
                providerOfficeInformationData= {
                  locationId: 0,
                  providerId:this.providerData$.providerDetails?.providerId,
                  locationType:this.formGroup.get('ddloffice_Type')?.value, 
                  phone:this.formGroup.get('office_Phone')?.value, 
                  fax:this.formGroup.get('office_Fax')?.value,                                             
                  monHours:'',                  
                  tuesHours:'',
                  wedHours: '',
                  thursHours: '',
                  friHours: '',
                  satHours: '',
                  sunHours: '',
                  streetAddress1:this.formGroup.get('office_Address1')?.value,
                  streetAddress2:this.formGroup.get('office_Address2')?.value,
                  city:this.formGroup.get('office_City')?.value,
                  state:this.formGroup.get('office_State')?.value,
                  zip:this.formGroup.get('office_Zip')?.value,                
                  createdDate:moment().format('YYYY-MM-DDTHH:mm:ss'),
                  createdBy:this.currentStorageService.userName,
                  updatedDate:moment().format('YYYY-MM-DDTHH:mm:ss'),
                  updatedBy:this.currentStorageService.userName    
                };  
                //code to update office hours here
                let officeInfoData: IProviderOfficeInformationModel; 
                let propertyOfficeInfokey: keyof typeof officeInfoData;       
                let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId=='4');
                for (var i = 0; i < filteredFormFields.length; i++) {
                  for (propertyOfficeInfokey in providerOfficeInformationData) 
                  {
                    if (filteredFormFields[i].dataKey == propertyOfficeInfokey)
                    {
                      if (filteredFormFields[i].fieldName.includes('hrs')){
                         //set office hours only if address tye is not billing
                        if (this.formGroup.get('ddloffice_Type')?.value!='Billing')
                        {
                          if(this.addOfficeHourComponent.officeHours!=null && this.addOfficeHourComponent.officeHours!=undefined)
                          {
                            if(this.addOfficeHourComponent.officeHours.length>0)
                            {
                              var idx = this.addOfficeHourComponent.officeHours.findIndex( o => o.key ==filteredFormFields[i].fieldName);
                              if (idx != -1)
                              {
                                var filteredOfficeHrs = this.addOfficeHourComponent.officeHours[idx];
                                if (this.isOfficeClosed(filteredFormFields[i].fieldName) ==false && (filteredOfficeHrs.min !=null && filteredOfficeHrs.min !=undefined && filteredOfficeHrs.min !='') && ( filteredOfficeHrs.max !=null && filteredOfficeHrs.max !=undefined && filteredOfficeHrs.max !=''))
                                {
                                  //set office hours if not closed
                                  this.setLocationObjectData(filteredOfficeHrs.min + '-' + filteredOfficeHrs.max,providerOfficeInformationData,propertyOfficeInfokey);
                                }
                                else
                                {  
                                  //set closed for office hours                                                             
                                  this.setLocationObjectData(this.isOfficeClosed(filteredFormFields[i].fieldName)? 'Closed' : '',providerOfficeInformationData,propertyOfficeInfokey);                          
                                } 
                              }
                            }
                          }
                        }
                        else
                        {
                          //set blank for office hours for billing address type
                          this.setLocationObjectData('',providerOfficeInformationData,propertyOfficeInfokey);
                        }                                                                         
                      }
                    }
                  }
                }
                //console.log(providerOfficeInformationData);
              
                //validate office hours here
               if(this.ValidateOfficeHours(providerOfficeInformationData))
                {
                  //call api method to insert office information data
                  this.providerOfficeInformation$.push(providerOfficeInformationData);                                                            
                  this.providerAttestationService.addProviderLocation(providerOfficeInformationData).subscribe({
                    next: async(response :IProviderOfficeInformationModel) => {
                      await this.getProviderData(true);
                        //this.specialtyErrorMessage="";
                      },
                      error: (e) => {
                        this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_OFFICE_INFORMATION_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
                      },
                      complete: () => {
                        this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_OFFICE_INFORMATION_ADD,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
                      }
                  });
                }
              }              
            }
            else
            {
              //edit office information
              this.setProviderData('4');  
            }
            
            //if office hours not failed then add/update perform fine and then only we need to disable the office information
            if(this.officeInformationErrorMessage=='')
            {
              this.disableFromControl('4');          
              this.isOfficeInformationSectionEditable =false;  
              this.addOfficeHourComponent.officeHoursFormAction='';
            }            
            //console.log(this.providerOfficeInformation$);
          } 
        } 
      }     
    }

    isOfficeClosed(fieldname:string){
      let isClosed :Boolean = false;
      if (fieldname !=null && fieldname !=undefined && fieldname!=''){
        switch(fieldname.replace('office','').replace('hrs',''))
        {
          case "Mon":
              if (this.addOfficeHourComponent.chkoffice_Mon.nativeElement != null && this.addOfficeHourComponent.chkoffice_Mon.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Mon.nativeElement.checked;
              }
            break;
            case "Tue":
              if (this.addOfficeHourComponent.chkoffice_Tue.nativeElement != null && this.addOfficeHourComponent.chkoffice_Tue.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Tue.nativeElement.checked;
              }
            break;
            case "Wed":
              if (this.addOfficeHourComponent.chkoffice_Wed.nativeElement != null && this.addOfficeHourComponent.chkoffice_Wed.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Wed.nativeElement.checked;
              }
            break;
            case "Thu":
              if (this.addOfficeHourComponent.chkoffice_Thu.nativeElement != null && this.addOfficeHourComponent.chkoffice_Thu.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Thu.nativeElement.checked;
              }
            break;
            case "Fri":
              if (this.addOfficeHourComponent.chkoffice_Fri.nativeElement != null && this.addOfficeHourComponent.chkoffice_Fri.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Fri.nativeElement.checked;
              }
            break;
            case "Sat":
              if (this.addOfficeHourComponent.chkoffice_Sat.nativeElement != null && this.addOfficeHourComponent.chkoffice_Sat.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Sat.nativeElement.checked;
              }
            break;
            case "Sun":
              if (this.addOfficeHourComponent.chkoffice_Sun.nativeElement != null && this.addOfficeHourComponent.chkoffice_Sun.nativeElement != undefined)
              {
                isClosed=this.addOfficeHourComponent.chkoffice_Sun.nativeElement.checked;
              }
            break;
        }
      }
      return isClosed;
    }

    deleteOfficeInformation(id?:number):void{
      //console.log(id);
      const confirmationModal = this.alertConfirmationModal.open().subscribe((value) => {
        if (value === 'submit') {
          this.officeInformationErrorMessage="";
          if(this.providerOfficeInformation$ !=null && this.providerOfficeInformation$!=undefined)
          {
            let index = this.providerOfficeInformation$.findIndex((obj => obj.locationId == id));
            if (index != -1){
              let providerOfficeInformationData = this.providerOfficeInformation$[index];
              providerOfficeInformationData.isDeleted=true;
              providerOfficeInformationData.updatedBy=this.currentStorageService.userName;
              providerOfficeInformationData.updatedDate = moment().format('YYYY-MM-DDTHH:mm:ss');
              this.updateProviderOfficeInformation(providerOfficeInformationData,false);
              this.providerOfficeInformation$.splice(index,1);
            }
          }
          confirmationModal.unsubscribe();
        }
      });
      
      this.disableFromControl('4');
      this.isOfficeInformationSectionEditable =false;
    }

    cancelOfficeInformation():void{
      if(this.isOfficeInformationSectionEditable==true){
      this.officeInformationErrorMessage="";
      this.ResetFromControl('4');
      this.disableFromControl('4');      
      this.isOfficeInformationSectionEditable =false;     
      this.addOfficeHourComponent.officeHoursFormAction='';
      }
    }

    OpenOfficeHoursModal(){
      const addOfficeHoursModal = this.addOfficeHoursModal.open().subscribe(async (_) => {  
         await this.initAddOfficeHoursForm.next(true);
         await addOfficeHoursModal.unsubscribe();         
      });
    }
      
    viewOfficeHoursModal(id?:number){
      this.showReadonlyModal=true;
      this.selectedLocationId = id;
      this.modalwidth='350px';
      this.addOfficeHourComponent.officeHoursFormAction='View';
      this.addOfficeHourComponent.locations$ = this.providerOfficeInformation$;
      this.addOfficeHourComponent.selectedLocationId = id;

      if (this.providerOfficeInformation$ != null && this.providerOfficeInformation$!=undefined)
      {
        let index = this.providerOfficeInformation$.findIndex((obj => obj.locationId == id));
        if (index != -1)
        {         
          let providerOfficeInformationData = this.providerOfficeInformation$[index];  
          this.addOfficeHourComponent.monOfficeHrs =(providerOfficeInformationData.monHours !=null && providerOfficeInformationData.monHours!=undefined) ? this.convertTime(providerOfficeInformationData.monHours):'00:00';
          this.addOfficeHourComponent.tueOfficeHrs =(providerOfficeInformationData.tuesHours !=null && providerOfficeInformationData.tuesHours!=undefined) ? this.convertTime(providerOfficeInformationData.tuesHours):'00:00';
          this.addOfficeHourComponent.wedOfficeHrs =(providerOfficeInformationData.wedHours !=null && providerOfficeInformationData.wedHours!=undefined) ? this.convertTime(providerOfficeInformationData.wedHours):'00:00';
          this.addOfficeHourComponent.thuOfficeHrs =(providerOfficeInformationData.thursHours !=null && providerOfficeInformationData.thursHours!=undefined) ? this.convertTime(providerOfficeInformationData.thursHours):'00:00';
          this.addOfficeHourComponent.friOfficeHrs =(providerOfficeInformationData.friHours !=null && providerOfficeInformationData.friHours!=undefined) ? this.convertTime(providerOfficeInformationData.friHours):'00:00';
          this.addOfficeHourComponent.satOfficeHrs =(providerOfficeInformationData.satHours !=null && providerOfficeInformationData.satHours!=undefined) ? this.convertTime(providerOfficeInformationData.satHours):'00:00';
          this.addOfficeHourComponent.sunOfficeHrs =(providerOfficeInformationData.sunHours !=null && providerOfficeInformationData.sunHours!=undefined) ? this.convertTime(providerOfficeInformationData.sunHours):'00:00'; 
        }
      }
      this.OpenOfficeHoursModal();               
    }
   
    addOfficeHours(){
      if (this.addOfficeHoursForm.valid) {
        this.addOfficeHourComponent.officeHours=[
          { key:'officeMonhrs',min:this.addOfficeHoursForm.value.office_MonToHour,max:this.addOfficeHoursForm.value.office_MonFromHour },
          { key:'officeTuehrs',min:this.addOfficeHoursForm.value.office_TueToHour,max:this.addOfficeHoursForm.value.office_TueFromHour },
          { key:'officeWedhrs',min:this.addOfficeHoursForm.value.office_WedToHour,max:this.addOfficeHoursForm.value.office_WedFromHour },
          { key:'officeThuhrs',min:this.addOfficeHoursForm.value.office_ThuToHour,max:this.addOfficeHoursForm.value.office_ThuFromHour },
          { key:'officeFrihrs',min:this.addOfficeHoursForm.value.office_FriToHour,max:this.addOfficeHoursForm.value.office_FriFromHour },
          { key:'officeSathrs',min:this.addOfficeHoursForm.value.office_SatToHour,max:this.addOfficeHoursForm.value.office_SatFromHour },
          { key:'officeSunhrs',min:this.addOfficeHoursForm.value.office_SunToHour,max:this.addOfficeHoursForm.value.office_SunFromHour }          
        ]
        //console.log(this.officeHours);
        this.formGroup.get('office_ToHour')?.setValue(this.addOfficeHoursForm.value.office_MonToHour);
        this.formGroup.get('office_FromHour')?.setValue(this.addOfficeHoursForm.value.office_MonFromHour);      
      }
    }
   
    resetOfficeHours(){
      //this method upon close/cancel of add office hours popup.
      if (this.isOfficeInformationAddClick == false)
      {
        this.addOfficeHourComponent.isMonToValue = false;
        this.addOfficeHourComponent.locations$ = this.providerOfficeInformation$;
        this.addOfficeHourComponent.selectedLocationId = this.selectedLocationId;
        
        if (this.providerOfficeInformation$ != null && this.providerOfficeInformation$!=undefined)
         {
           let index = this.providerOfficeInformation$.findIndex((obj => obj.locationId == this.selectedLocationId));
           if (index != -1)
           {         
             let providerOfficeInformationData = this.providerOfficeInformation$[index];   
   
             this.addOfficeHourComponent.formGroup.get('office_MonToHour')?.setValue((providerOfficeInformationData.monHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.monHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_MonFromHour')?.setValue((providerOfficeInformationData.monHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.monHours,false):'00:00');
             this.addOfficeHourComponent.isMonClose =(providerOfficeInformationData.monHours == 'Closed');
             let ctrl_chkMon = this.addOfficeHoursForm.get('chkoffice_Mon') as FormControl;
             ctrl_chkMon.patchValue(providerOfficeInformationData.monHours == 'Closed');
   
             this.addOfficeHourComponent.formGroup.get('office_TueToHour')?.setValue((providerOfficeInformationData.tuesHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.tuesHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_TueFromHour')?.setValue((providerOfficeInformationData.tuesHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.tuesHours,false):'00:00');
             let ctrl_chkTue = this.addOfficeHoursForm.get('chkoffice_Tue') as FormControl;
             ctrl_chkTue.patchValue(providerOfficeInformationData.tuesHours == 'Closed');
   
             this.addOfficeHourComponent.formGroup.get('office_WedToHour')?.setValue((providerOfficeInformationData.wedHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.wedHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_WedFromHour')?.setValue((providerOfficeInformationData.wedHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.wedHours,false):'00:00');
             let ctrl_chkWed = this.addOfficeHoursForm.get('chkoffice_Wed') as FormControl;
             ctrl_chkWed.patchValue(providerOfficeInformationData.wedHours == 'Closed');
   
             this.addOfficeHourComponent.formGroup.get('office_ThuToHour')?.setValue((providerOfficeInformationData.thursHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.thursHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_ThuFromHour')?.setValue((providerOfficeInformationData.thursHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.thursHours,false):'00:00');
             let ctrl_chkThu = this.addOfficeHoursForm.get('chkoffice_Thu') as FormControl;
             ctrl_chkThu.patchValue(providerOfficeInformationData.thursHours == 'Closed');
   
             this.addOfficeHourComponent.formGroup.get('office_FriToHour')?.setValue((providerOfficeInformationData.friHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.friHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_FriFromHour')?.setValue((providerOfficeInformationData.friHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.friHours,false):'00:00');
             let ctrl_chkFri = this.addOfficeHoursForm.get('chkoffice_Fri') as FormControl;
             ctrl_chkFri.patchValue(providerOfficeInformationData.friHours == 'Closed');
   
             this.addOfficeHourComponent.formGroup.get('office_SatToHour')?.setValue((providerOfficeInformationData.satHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.satHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_SatFromHour')?.setValue((providerOfficeInformationData.satHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.satHours,false):'00:00');
             let ctrl_chkSat = this.addOfficeHoursForm.get('chkoffice_Sat') as FormControl;
             ctrl_chkSat.patchValue(providerOfficeInformationData.satHours == 'Closed');
   
             this.addOfficeHourComponent.formGroup.get('office_SunToHour')?.setValue((providerOfficeInformationData.sunHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.sunHours,true):'00:00');
             this.addOfficeHourComponent.formGroup.get('office_SunFromHour')?.setValue((providerOfficeInformationData.sunHours != 'Closed')?CommonUtil.getParseData(providerOfficeInformationData.sunHours,false):'00:00');
             let ctrl_chkSun = this.addOfficeHoursForm.get('chkoffice_Sun') as FormControl;
             ctrl_chkSun.patchValue(providerOfficeInformationData.sunHours == 'Closed');
   
             this.addOfficeHourComponent.isMonClose =(providerOfficeInformationData.monHours =='Closed');
             this.addOfficeHourComponent.isTueClose =(providerOfficeInformationData.tuesHours =='Closed');
             this.addOfficeHourComponent.isWedClose =(providerOfficeInformationData.wedHours =='Closed');
             this.addOfficeHourComponent.isThuClose =(providerOfficeInformationData.thursHours =='Closed');
             this.addOfficeHourComponent.isFriClose =(providerOfficeInformationData.friHours =='Closed');
             this.addOfficeHourComponent.isSatClose =(providerOfficeInformationData.satHours =='Closed');
             this.addOfficeHourComponent.isSunClose =(providerOfficeInformationData.sunHours =='Closed');
   
             this.addOfficeHourComponent.officeHours=[
               { key:'officeMonhrs',min:CommonUtil.getParseData(providerOfficeInformationData.monHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.monHours,false)},
               { key:'officeTuehrs',min:CommonUtil.getParseData(providerOfficeInformationData.tuesHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.tuesHours,false)},
               { key:'officeWedhrs',min:CommonUtil.getParseData(providerOfficeInformationData.wedHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.wedHours,false)},
               { key:'officeThuhrs',min:CommonUtil.getParseData(providerOfficeInformationData.thursHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.thursHours,false)},
               { key:'officeFrihrs',min:CommonUtil.getParseData(providerOfficeInformationData.friHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.friHours,false)},
               { key:'officeSathrs',min:CommonUtil.getParseData(providerOfficeInformationData.satHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.satHours,false)},
               { key:'officeSunhrs',min:CommonUtil.getParseData(providerOfficeInformationData.sunHours,true),max:CommonUtil.getParseData(providerOfficeInformationData.sunHours,false)},
             ];
   
             //console.log(this.addOfficeHourComponent.officeHours);
           }
         }
      }
      else
      {
        this.addOfficeHourComponent.isMonClose =false;
        this.addOfficeHourComponent.isTueClose =false;
        this.addOfficeHourComponent.isWedClose =false;
        this.addOfficeHourComponent.isThuClose =false;
        this.addOfficeHourComponent.isFriClose =false;
        this.addOfficeHourComponent.isSatClose =false;
        this.addOfficeHourComponent.isSunClose =false;
        this.addOfficeHourComponent.formGroup.reset();
      }
    }
    intializeOfficeHours(){
      // console.log('open')
      // if(this.addOfficeHourComponent.officeHoursFormAction=='Add')
      //   this.addOfficeHoursForm.reset();
    }
     onAddOfficeHoursValidate(form: FormGroup) {
      this.addOfficeHoursForm = form;
    }
    /*****************************SECTION 4 - OFFICE INFORMATION SECTION ENDS HERE *************************************/

    /*****************************SECTION 5 - SPECIALTY SECTION *************************************/
    addSpecialty():void{
      this.specialtyErrorMessage="";
      this.ResetFromControl('5');
      this.enableFromControl('5');
      this.isSpecialtySectionEditable=true;
    }

    editSpecialty(id:number):void{
      //console.log(id);
      this.specialtyErrorMessage=""
      if (this.providerSpecialty$ != null && this.providerSpecialty$!=undefined)
      {
        let index = this.providerSpecialty$.findIndex((obj => obj.specialtyId == id));
      let providerSpecialtyData = this.providerSpecialty$[index];     
      this.enableFromControl('5');
      this.formGroup.get('specialty_Id')?.setValue(providerSpecialtyData.specialtyId);      
      this.formGroup.get('specialty_ProviderSpecialty')?.setValue(providerSpecialtyData.specialty);
      this.formGroup.get('specialty_EffectiveDate')?.setValue(providerSpecialtyData.effectiveDate);      
      this.isSpecialtySectionEditable=true;
      }      
    }

    deleteSpecialty(id:number):void{
      const confirmationModal = this.alertConfirmationModal.open().subscribe((value) => {
        if (value === 'submit') {
          this.specialtyErrorMessage="";
          if (this.providerSpecialty$ != null && this.providerSpecialty$!=undefined)
          {
            let index = this.providerSpecialty$.findIndex((obj => obj.specialtyId == id));
            if (index != -1){
              let providerSpecialtyData = this.providerSpecialty$[index];
              providerSpecialtyData.isDeleted=true;
              providerSpecialtyData.updatedBy=this.currentStorageService.userName;
              providerSpecialtyData.updatedDate = moment().format('YYYY-MM-DDTHH:mm:ss');
              this.updateSpecialty(providerSpecialtyData,false);
              this.providerSpecialty$.splice(index,1);
            }      
          }
          confirmationModal.unsubscribe();
        }
      });
            
      this.disableFromControl('5');
      this.isSpecialtySectionEditable =false;
    }

    saveSpecialty():void{
      if (this.isSpecialtySectionEditable){
        if (this.ValidateForm('5',false)) {
          if(this.validateSpecialty(this.formGroup.get('specialty_ProviderSpecialty')?.value))
          {
            if(this.isSpecialtySectionEditable){
              let providerSpecialtyData: IProviderSpecialtyModel;
              if(this.formGroup.get('specialty_Id')?.value == '')
              {                
                //Add new specialty.
                if (this.providerSpecialty$ != null && this.providerSpecialty$!=undefined)
                {
                  providerSpecialtyData =
                    {
                      specialtyId: 0,
                      providerId:this.providerData$.providerDetails.providerId,                  
                      specialty: this.formGroup.get('specialty_ProviderSpecialty')?.value,
                      effectiveDate:this.formGroup.get('specialty_EffectiveDate')?.value,
                      createdDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                      createdBy:this.currentStorageService.userName,
                      updatedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                      updatedBy:this.currentStorageService.userName,
                      isDeleted:false  
                    };
                    this.providerSpecialty$.push(providerSpecialtyData);
                    this.providerAttestationService.addProviderSpecialty(providerSpecialtyData).subscribe({
                    next: async(response :IProviderSpecialtyModel) => {
                      await this.getProviderData(true);
                      this.ResetFromControl('5');
                      this.disableFromControl('5');          
                      this.isSpecialtySectionEditable =false; 
                      this.specialtyErrorMessage="";
                      },
                      error: (e) => {
                        this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_SPECIALTY_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR)
                      },
                      complete: () => {
                        this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_SPECIALTY_ADD,'OK',ENUM.MESSAGE_TYPE.SUCCESS)
                      }
                  });
                }
              }
              else
              {
                //edit specialty                
                if (this.providerSpecialty$ != null && this.providerSpecialty$!=undefined)
                {                  
                  let index = this.providerSpecialty$.findIndex((obj => obj.specialtyId == this.formGroup.get('specialty_Id')?.value));
                  if (index !=-1){
                      providerSpecialtyData = this.providerSpecialty$[index];
                      providerSpecialtyData.specialtyId = this.formGroup.get('specialty_Id')?.value;                 
                      providerSpecialtyData.specialty =this.formGroup.get('specialty_ProviderSpecialty')?.value;
                      providerSpecialtyData.effectiveDate = this.formGroup.get('specialty_EffectiveDate')?.value;  
                      providerSpecialtyData.updatedBy = this.currentStorageService.userName;
                      providerSpecialtyData.updatedDate = moment().format('YYYY-MM-DDTHH:mm:ss'); 
                      this.updateSpecialty(providerSpecialtyData,true);
                    }
                 }  
              }              
            }  
          }                 
        }
      }
    }
    
    updateSpecialty(providerSpecialtyData:IProviderSpecialtyModel,isUpdate:boolean){
      //console.log(providerSpecialtyData);
      this.providerAttestationService.updateProviderSpecialty(providerSpecialtyData).subscribe({
        next: async(response :IProviderSpecialtyModel) => {
            await this.getProviderData(true);
            this.ResetFromControl('5');
            this.disableFromControl('5');          
            this.isSpecialtySectionEditable =false; 
            this.specialtyErrorMessage="";
          },
          error: (e) => {
            if(isUpdate)
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_SPECIALTY_UPDATE_ERROR,"OK",ENUM.MESSAGE_TYPE.ERROR)
            else
            this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_SPECIALTY_DELETE_ERROR,"OK",ENUM.MESSAGE_TYPE.ERROR)
          },
          complete: () => {
            if(isUpdate)
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_SPECIALTY_UPDATE,"OK",ENUM.MESSAGE_TYPE.SUCCESS)
            else
            this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_SPECIALTY_DELETE,"OK",ENUM.MESSAGE_TYPE.SUCCESS)
          }
      }); 
    }

    updateProviderOfficeInformation(providerOfficeInformationData:IProviderOfficeInformationModel,isUpdate:boolean){
      //console.log(providerOfficeInformationData);
      providerOfficeInformationData.updatedBy=this.currentStorageService.userName;
      providerOfficeInformationData.updatedDate =  moment().format('YYYY-MM-DDTHH:mm:ss');
      this.providerAttestationService.updateProviderLocation(providerOfficeInformationData).subscribe({
        next: async(response :IProviderOfficeInformationModel) => {
            await this.getProviderData(true);           
          },
          error: (e) => {
            if(isUpdate)
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_OFFICE_INFORMATION_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
            else
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_OFFICE_INFORMATION_DELETE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
          },
          complete: () => {
            if(isUpdate)
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_OFFICE_INFORMATION_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
            else
              this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_OFFICE_INFORMATION_DELETE,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
          }
      }); 
    }

    cancelSpecialty():void{
      if(this.isSpecialtySectionEditable)
      {    
        this.specialtyErrorMessage="";    
        this.ResetFromControl('5');
        this.disableFromControl('5'); 
        this.isSpecialtySectionEditable =false;
      }      
    }
    /*****************************SECTION 4 - SPECIALTY SECTION ENDS HERE*************************************/

    disableFromControl(frmSections:string)
    {      
      let sections:string[] = frmSections.split(',');
      sections.forEach( (section) => {
        let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
        for (var i = 0; i < filteredFormFields.length; i++) {
          if(filteredFormFields[i].fieldType != null && filteredFormFields[i].fieldType !=undefined){
            this.formGroup.get(filteredFormFields[i].fieldName)?.disable();                           
          } 
        }
      });
    }

    enableFromControl(frmSections:string)
    { 
      let frmKey: keyof typeof this.formGroup.controls; 
      let sections:string[] = frmSections.split(',');
      sections.forEach( (section) => {
        let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
        for (var i = 0; i < filteredFormFields.length; i++) {
          this.formGroup.get(filteredFormFields[i].fieldName)?.enable();
          if(this.formGroup.get(filteredFormFields[i].fieldName)?.hasError('empty'))
            this.formGroup.get(filteredFormFields[i].fieldName)?.markAsUntouched();          
        }
      });            
    }

    disableFormControlForFormGroup(frmSections:string, formGroup: FormGroup)
    {      
      let sections:string[] = frmSections.split(',');
      sections.forEach( (section) => {
        let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
        for (var i = 0; i < filteredFormFields.length; i++) {
          if(filteredFormFields[i].fieldType != null && filteredFormFields[i].fieldType !=undefined){
            formGroup.get(filteredFormFields[i].fieldName)?.disable();                           
          } 
        }
      });
    }
    enableFormControlForFormGroup(frmSections:string, formGroup: FormGroup)
    { 
      let frmKey: keyof typeof formGroup.controls; 
      let sections:string[] = frmSections.split(',');
      sections.forEach( (section) => {
        let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
        for (var i = 0; i < filteredFormFields.length; i++) {
          formGroup.get(filteredFormFields[i].fieldName)?.enable();
          if(formGroup.get(filteredFormFields[i].fieldName)?.hasError('empty'))
            formGroup.get(filteredFormFields[i].fieldName)?.markAsUntouched();          
        }
      });            
    }

    ResetFromControl(frmSection:string)
    { 
      let sections:string[] = frmSection.split(',');
      sections.forEach( (section) => {
        let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
        for (var i = 0; i < filteredFormFields.length; i++) {
          if (filteredFormFields[i].fieldType =='text')
            this.formGroup.get(filteredFormFields[i].fieldName)?.setValue('');
            if (filteredFormFields[i].fieldType =='dropdown')
            {
              switch(section)
              {
                case "4":                      
                  this.formGroup.get(filteredFormFields[i].fieldName)?.setValue('Primary');                        
                  break;
                case "5":                      
                  this.formGroup.get(filteredFormFields[i].fieldName)?.setValue('Yes');
                  break;
              }
            }
          this.formGroup.get(filteredFormFields[i].fieldName)?.setValue('');
        }
      });      
    }
    
    ValidateForm(frmSection:string,validateAllField:boolean):boolean{      
      let isvalid: boolean = true;
      let sections:string[] = frmSection.split(',');
      sections.forEach( (section) => {
        let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId==section);
        for (var i = 0; i < filteredFormFields.length; i++) {
          if (filteredFormFields[i].isRequired)
          {
            //data/text/DROPDOWN/checkbox
            if (filteredFormFields[i].fieldType == 'text' || filteredFormFields[i].fieldType == 'date' || filteredFormFields[i].fieldType == 'dropdown'){
              if(filteredFormFields[i].isRange)
              {
                //valid age range
                if (filteredFormFields[i].fieldName.toLowerCase().includes('age'))
                {
                  this.validateAgeRestriction(this.formGroup.get(filteredFormFields[i].fieldName));
                  if(this.isValidAgeRange == false)
                  {
                    //upon attestation button click
                    if(validateAllField)
                    {                      
                      if(section=='1a' && this.isEditable ==false)
                      {
                        this.enableFromControl(section);
                        this.isEditable= true;
                      }
                    }
                    this.formGroup.get(filteredFormFields[i].fieldName)?.markAsTouched();
                    isvalid = false;
                  }

                  if (this.formGroup.get(filteredFormFields[i].fieldName)?.value==''){
                    //upon attestation button click
                    if(validateAllField)
                    {                      
                      if(section=='1a' && this.isEditable ==false)
                      {
                        this.enableFromControl(section);
                        this.isEditable= true;
                      }
                    }
                    this.formGroup.get(filteredFormFields[i].fieldName)?.markAsTouched();
                    isvalid = false;
                  }
                }
                //valid amount
                if (filteredFormFields[i].fieldName.toLowerCase().includes('amount'))
                {
                  this.validateLiabilityAmount(this.formGroup.get(filteredFormFields[i].fieldName));
                  if(this.isValidAmountRange == false)
                  {
                    if(validateAllField)
                    {                      
                      if(section=='3' && this.isProviderLiabilitySectionEditable ==false)
                      {
                        this.enableFromControl(section);
                        this.isProviderLiabilitySectionEditable= true;
                      }
                    }
                    this.formGroup.get(filteredFormFields[i].fieldName)?.markAsTouched();
                    isvalid = false;
                  }
                  if (this.formGroup.get(filteredFormFields[i].fieldName)?.value==''){
                    if(validateAllField)
                    {                      
                      if(section=='3' && this.isProviderLiabilitySectionEditable ==false)
                      {
                        this.enableFromControl(section);
                        this.isProviderLiabilitySectionEditable= true;
                      }
                    }
                    this.formGroup.get(filteredFormFields[i].fieldName)?.markAsTouched();
                    isvalid = false;
                  }
                
                }              
              }
              else
              {                
                //if field is text or dropdown and is required on the form
                if (this.formGroup.get(filteredFormFields[i].fieldName)?.value == '' || 
                this.formGroup.get(filteredFormFields[i].fieldName)?.value == "Invalid date" ||
                this.formGroup.get(filteredFormFields[i].fieldName)?.hasError('pattern'))
                {                  
                  if(validateAllField)
                  {
                    if(section=='1a' && this.isEditable ==false)
                    {
                      this.enableFromControl(section);
                      this.isEditable= true;
                    }
                    if(section=='1b' && this.isProviderLicenseSectionEditable ==false)
                    {
                      this.enableFromControl(section);
                      this.isProviderLicenseSectionEditable= true;
                    }
                    if(section=='1c' && this.isProviderContractInfoSectionEditable ==false)
                    {
                      this.enableFromControl(section);
                      this.isProviderContractInfoSectionEditable= true;
                    }
                    if(section=='2' && this.isProviderBoardTrainingSectionEditable ==false)
                    {
                      this.enableFormControlForFormGroup(section, this.boardSectionGroup);
                      this.isProviderBoardTrainingSectionEditable= true;
                    }
                    if(section=='3' && this.isProviderLiabilitySectionEditable ==false)
                    {
                      this.enableFromControl(section);
                      this.isProviderLiabilitySectionEditable= true;
                    }                    
                  }                  
                  this.formGroup.get(filteredFormFields[i].fieldName)?.markAsTouched();
                  isvalid = false;
                }
              }
            }
          }
          else{
            //conditionally required section            
            //validation for PCP and speciality check box
            if (filteredFormFields[i].fieldName == 'pi_chkPCP' || filteredFormFields[i].fieldName == 'pi_chkSpecialty'){
              if (this.formGroup.get('pi_chkPCP')?.value == '' && this.formGroup.get('pi_chkSpecialty')?.value == ''){
                if(validateAllField)
                {
                  if(section=='1a' && this.isEditable ==false)
                  {
                    this.enableFromControl(section);
                    this.isEditable= true;
                  }
                }
                this.formGroup.get('pi_chkPCP')?.markAsTouched(); 
                this.formGroup.get('pi_chkSpecialty')?.markAsTouched();                 
                isvalid = false;
              }             
            }          
          }
        }
      });
      return isvalid;
    }

    SaveAttestation(){
      if(this.validateAttestation())
      {
        const confirmationModal = this.alertAttestationConfirmationModal.open().subscribe((value) => {
          if (value === 'submit') {
            this.attestationErrorMessage='';
          //api call code here to save attestation.
          let providerAttestationData : IProviderAttestationModel={
            isDeleted:false,
            createdDate:  moment().format('YYYY-MM-DDTHH:mm:ss'),
            updatedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            createdBy: this.currentStorageService.userName,
            updatedBy: this.currentStorageService.userName,
            attestationId:0,
            providerId:this.providerData$.providerDetails.providerId,
            isAttested:true,
            attestedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
            attestedBy: this.currentStorageService.userName,
            isCompleted: false
          }
          this.providerAttestationService.addProviderAttestation(providerAttestationData).subscribe({
            next: async(response :IProviderAttestationModel) => {
              await this.getProviderData(false);                         
              },
              error: (e) => {
                this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_ATTESTATION_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);                 
              },
              complete: () => { 
                this.isReadOnly=true;              
                this.toast.openSnackBar(CONSTANT.MSG_PROVIDER_ATTESTATION_ADD,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
              }
          });
          confirmationModal.unsubscribe();
          }
        });


      } 
      window.scrollTo(0,0);        
    }
    
    validateAttestation(){
      let isValid: boolean = true;

      this.attestationErrorMessage = '<ul>'
      let isRequiredFieldValidation:boolean=true;
           
      //if all the abaove validation passed then save the attestation.
      //check for unsaved data for any UI section
      if (!this.isEditable && !this.isProviderContractInfoSectionEditable && !this.isSpecialtySectionEditable && !this.isProviderLicenseSectionEditable && !this.isProviderBoardTrainingSectionEditable && !this.isProviderLiabilitySectionEditable && !this.isOfficeInformationSectionEditable)
      {
         //validate required field
         isRequiredFieldValidation = this.ValidateForm('1a,1b,1c,2,3',true)
         if(isRequiredFieldValidation==false)
           this.attestationErrorMessage = this.attestationErrorMessage + '<li>Please enter all the required fields on the form.</li>'
         
         //validate provider has atleast one specialty
         if(this.providerData$.specialtyDetails.length==0)
         {
           this.attestationErrorMessage = this.attestationErrorMessage + '<li>Provider must have alteast one specialty. Please add the specialty in Specialty section and try again.</li>'          
         }
 
         if(this.boardSectionGroup.controls["pb_boardname"].value){
          this.isProviderBoardTrainingSectionEditable = true;
          this.boardNameChangeEnableDisable(this.boardSectionGroup.controls["pb_boardname"].value);    
          if(!this.isBoardSectionValid()){
            if(isRequiredFieldValidation)
              this.attestationErrorMessage = this.attestationErrorMessage + '<li>Please enter all the required fields on the form.</li>'
            this.editProviderBoardTraining();
          }  
          else
          {
            this.isProviderBoardTrainingSectionEditable = false;
            var effectivedate = this.boardSectionGroup.get('pb_effectivedate');
            var expirationDate = this.boardSectionGroup.get('pb_expirationdate');
            var boardStatus = this.boardSectionGroup.controls["ddlpb_boardCertificationStatus"]
            boardStatus?.disable();
            effectivedate?.disable();            
            expirationDate?.disable();
          }
         }
         

         //validate provider must have primary and business address
         if(this.providerData$.locationDetails.filter(s => s.locationType=='Primary').length==0)
           this.attestationErrorMessage = this.attestationErrorMessage + '<li>Provider must have a primary address. Please add the primary address in Office Information section and try again.</li>'          
 
         if(this.providerData$.locationDetails.filter(s => s.locationType=='Billing').length==0)
           this.attestationErrorMessage = this.attestationErrorMessage + '<li>Provider must have a billing address. Please add the billing address in Office Information section and try again.</li>'          

                  

         this.validateOfficeInformationSection();
         isValid =!(this.attestationErrorMessage.includes('<li>'));
      }
      else
      {
       //if user has unsaved data for any UI section
       if (this.isEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in Provider Information section. Please save or cancel your changes and try again.</li>'
       if (this.isProviderContractInfoSectionEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in Contract Information section. Please save or cancel your changes and try again.</li>'
       if (this.isSpecialtySectionEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in Specialty section. Please save or cancel your changes and try again.</li>'
       if (this.isProviderLicenseSectionEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in License(s) section. Please save or cancel your changes and try again.</li>'
       if (this.isProviderBoardTrainingSectionEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in Board Training(s) section. Please save or cancel your changes and try again.</li>'
       if (this.isProviderLiabilitySectionEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in Liability / Malpractice Insurance section. Please save or cancel your changes and try again.</li>'
       if (this.isOfficeInformationSectionEditable)
         this.attestationErrorMessage = this.attestationErrorMessage + '<li>You have unsaved data in Office Information section. Please save or cancel your changes and try again.</li>'

         isValid =!(this.attestationErrorMessage.includes('<li>'));
      }
      if(isValid)
        this.attestationErrorMessage =''
      else
        this.attestationErrorMessage = this.attestationErrorMessage + '</ul>'
      
      return isValid;
    }

    isBoardSectionValid(): boolean {
      for (const key in this.boardSectionGroup.controls) {
        if (this.boardSectionGroup.controls[key].invalid) {
          return false;
        }
      }
      return true;
    }

    validateOfficeType():boolean{
      this.officeInformationErrorMessage="";
      let isValidOfficeType:boolean=true;
      if(this.formGroup.get('ddloffice_Type')?.value !='') {
        if(this.providerOfficeInformation$ != null && this.providerOfficeInformation$ !=undefined){
          let index =this.providerOfficeInformation$.findIndex((obj => obj.locationType == this.formGroup.get('ddloffice_Type')?.value));
          if (index !=-1){        
            let providerOfficeInformationData = this.providerOfficeInformation$[index];
            if(providerOfficeInformationData.locationId != this.formGroup.get('office_Id')?.value)
            {
              this.officeInformationErrorMessage= this.formGroup.get('ddloffice_Type')?.value + " office information already exists. Please review and try again.";
              isValidOfficeType = false;
            }
          } 
        }    
      }     
      return isValidOfficeType;
    }

    ValidateOfficeHours(locationData:IProviderOfficeInformationModel):boolean{
      let isValidOfficeHours:boolean=true;
      if (locationData !=null && locationData !=undefined){
        if(locationData.locationType !='Billing')
        {
          if(locationData.monHours== null || locationData.monHours == undefined || locationData.monHours=='' )
          {
            isValidOfficeHours=false;
          }
          if((locationData.tuesHours== null || locationData.tuesHours == undefined || locationData.tuesHours=='') &&  (isValidOfficeHours) )
          {
            isValidOfficeHours=false;
          }
          if((locationData.wedHours== null || locationData.wedHours == undefined || locationData.wedHours=='') &&  (isValidOfficeHours) )
          {
            isValidOfficeHours=false;
          }
          if((locationData.thursHours== null || locationData.thursHours == undefined || locationData.thursHours=='') &&  (isValidOfficeHours) )
          {
            isValidOfficeHours=false;
          }
          if((locationData.friHours== null || locationData.friHours == undefined || locationData.friHours=='') &&  (isValidOfficeHours) )
          {
            isValidOfficeHours=false;
          }
          if((locationData.satHours== null || locationData.satHours == undefined || locationData.satHours=='') &&  (isValidOfficeHours) )
          {
            isValidOfficeHours=false;
          }
          if((locationData.sunHours== null || locationData.sunHours == undefined || locationData.sunHours=='') &&  (isValidOfficeHours) )
          {
            isValidOfficeHours=false;
          }
        }
      }
      
      if(isValidOfficeHours==false)
        this.officeInformationErrorMessage='Please add or edit office hours and try again.';
      else
        this.officeInformationErrorMessage='';

      return isValidOfficeHours;
    }

    validateOfficeInformationSection():boolean{      
      let isValidOfficeHours:boolean=true;
      let isValidPrimaryOfficeHours:boolean=true;
      let isValidSecondaryOfficeHours:boolean=true;
      let isValidBillingOfficeHours:boolean=true;
      //Section 4 - Office Information
      let providerOfficeInformationData: IProviderOfficeInformationModel;         
      let propertyOfficeInformationkey: keyof typeof providerOfficeInformationData; 
      
      let filteredFormFields: IFormFieldModel[] = this.providerFormFields$.filter(f => f.sectionId=='4');
      for (var i = 0; i < filteredFormFields.length; i++) {
        if (filteredFormFields[i].dataKey !=null && filteredFormFields[i].dataKey!=undefined && filteredFormFields[i].dataKey!='')
        {
          if(this.providerData$.locationDetails !=null && this.providerData$.locationDetails!=undefined)
          {
            //loop for each office locations
            this.providerData$.locationDetails.forEach( (location) => {
              for (propertyOfficeInformationkey in location) 
              {                
                if (filteredFormFields[i].dataKey == propertyOfficeInformationkey)
                {
                  if (filteredFormFields[i].fieldType =='text') 
                  {
                    if (filteredFormFields[i].isRequired){                      
                      if (location[propertyOfficeInformationkey]== null ||  location[propertyOfficeInformationkey]=='' || location[propertyOfficeInformationkey]=='-' )
                      {                        
                        //called upon saving the attestation
                        if(this.attestationErrorMessage == '')
                        this.attestationErrorMessage = '<ul>'
                        //if primary address                        
                        if (location.locationType?.toLowerCase() =='primary')
                        {                                                                                  
                          if(isValidPrimaryOfficeHours)
                          {
                            isValidPrimaryOfficeHours =false;
                            this.attestationErrorMessage = this.attestationErrorMessage + `<li>${location.locationType} office location - has missing office hours or the required fields.</li>`
                          }
                        }

                        //if secondary address
                        if (location.locationType?.toLowerCase() =='secondary')
                        {
                          if(isValidSecondaryOfficeHours)
                          {
                            isValidSecondaryOfficeHours =false;
                            this.attestationErrorMessage = this.attestationErrorMessage + `<li>${location.locationType} office information has missing office hours or the required fields.</li>`                            
                          }
                        }

                        //if billing address
                        if (location.locationType?.toLowerCase() =='billing')
                        {
                          if(isValidBillingOfficeHours)
                          {
                            if(!propertyOfficeInformationkey.toLowerCase().includes('hours')){
                              isValidBillingOfficeHours=false;
                              this.attestationErrorMessage = this.attestationErrorMessage + `<li>${location.locationType} office information has missing required fields.</li>`                              
                            }                             
                          }                              
                        } 
                      }
                    }
                  }
                }                    
              }                
            });            
          }          
        }        
      }
      this.attestationErrorMessage= (this.attestationErrorMessage.includes('<ul>')? this.attestationErrorMessage + '</ul>' : '');
      isValidOfficeHours = (!isValidPrimaryOfficeHours || !isValidSecondaryOfficeHours ||  !isValidBillingOfficeHours);
      return isValidOfficeHours;
    }

    validateSpecialty(specialty:string):boolean{
      let isvalidSpecailty: boolean = true;
      if(this.providerSpecialty$.findIndex(f => f.specialty?.toLowerCase()==specialty.toLowerCase()) != -1)
      {
        this.specialtyErrorMessage="Speciality already exist."
        isvalidSpecailty = false;
      }
      return isvalidSpecailty;
    }

    validateAgeRestriction(field:any){
      if(field.value !='')
      {
        if (parseInt(this.formGroup.get('pi_ageMax')?.value)< parseInt(this.formGroup.get('pi_ageMin')?.value))       
          this.isValidAgeRange= false;       
        else if(parseInt(this.formGroup.get('pi_ageMax')?.value)>100 || parseInt(this.formGroup.get('pi_ageMin')?.value)>100){
          this.isValidAgeRange= false;       
        }          
        else
        this.isValidAgeRange= true;
      }      
    }

    validateLiabilityAmount(field:any){
      if(field.value !='')
      {
        if (parseInt(this.formGroup.get('pm_amountMax')?.value)< parseInt(this.formGroup.get('pm_amountMin')?.value))       
          this.isValidAmountRange= false;          
        else
        this.isValidAmountRange= true;
      }      
    }
    
    keyPressValidation(event:any,key:string){
      let index = this.patterns$.findIndex((obj => obj.key?.toLowerCase() == key.toLowerCase()));
      let isvalid! : boolean;
      if (index !=-1)
      {
        var str = String.fromCharCode(event.keyCode);
        let pattern = this.patterns$[index];       
        if (pattern.value.test(str)) {
          isvalid=true;
        } 
        else 
        {
          event.preventDefault();
          isvalid=false;          
        }
      }
      return isvalid;
    }

    convertTime(time?:string)
    {
      let retValue:string ='';
      if (time !=null && time !=undefined){
        if(time!=='' && time.includes('-'))
        {
          retValue= CommonUtil.standardTimeFormat(CommonUtil.getParseData(time,true)) + '-' + CommonUtil.standardTimeFormat(CommonUtil.getParseData(time,false));
        }
        else if (time == 'Closed')
          retValue =time;
      }
      //console.log(retValue);
      return retValue;
    }
  
    ngOnDestroy(): void {
    }

    isMaxLocations(): boolean {
      var result = false;
      if (this.providerData$ != undefined && this.providerData$ != null)
        if (this.providerData$.locationDetails!= undefined && this.providerData$.locationDetails !=null)
            if (this.providerData$.locationDetails.length >= 3)
              result = true;

      return result;
    }
  }

