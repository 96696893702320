import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from 'src/app/libs/modal/modal.component';
import { ProviderHistoryService } from './provider-history.service';
import { IProviderAttestationModel, IProviderBoard, IProviderHistoryModel, IProviderInsurance, IProviderModel, IProviderOfficeInformationModel, IProviderSpecialtyModel, INotificationStatus, INotificationStatusType, IProviderStatus, IProviderSearchModel } from 'src/app/app-store/entities/provider';
import { IProviderAttestationUser } from 'src/app/app-store/entities/user';
import moment from 'moment';
import { ProviderAttestationService } from '../ProviderAttestation/provider-attestation.service';
import { IConstant } from 'src/app/app-store/entities/constant';
import { AddOfficeHourComponent } from '../ProviderAttestation/Modals/add-office-hours/add-Office-hour.component';
import { CommonUtil } from 'src/app/shared/Util/common-util';
import { CurrentStorageService } from '../../core/services/current-storage.service';
import { ToastService } from '../../core/services/toast.service';
import * as CONSTANT from '../../core/constants';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as ENUM from '../../core/constants/enums'
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-provider-history-list',
    templateUrl: './provider-history.component.html',
    styleUrls: ['./provider-history.component.scss'],
    standalone: false
})

export class ProviderHistoryComponent implements OnInit {
  
  
  @ViewChild('alertLeavePageModal', { static: false })
  alertLeavePageModal!: ModalComponent;

 
  @ViewChild('attestationLinkModal', { static: false })
    attestationLinkModal!: ModalComponent;     

    @ViewChild('alertConfirmationModal', { static: false })
    alertConfirmationModal!: ModalComponent;     

    @ViewChild('addOfficeHoursModal', { static: false })
    addOfficeHoursModal!: ModalComponent;   

    @ViewChild('addOfficeHourComponent', { static: false })
    addOfficeHourComponent!: AddOfficeHourComponent; 
    showReadonlyModal:boolean=true;
    modalwidth!: string;
    showOfficeHours: boolean = true;
    baseUrl!: string;

    currentAndHistoryData$!:IProviderHistoryModel;
    constants$!:IConstant[];
    isLoading:boolean= true;
    providerId!: string;
    formGroup!: FormGroup; 
    providerMedicareStatusToDisplay!: string;
    providerMedicalStatusToDisplay!: string;
    providerPanelStatusToDisplay!: string;
    providerCertificationToDisplay!:string;
    providerPanelStatusToDisplayHistory!: string;
    providerMedicareStatusToDisplayHistory!: string;
    providerMedicalStatusToDisplayHistory!: string;
    speciality!: string[];
    specialityHistory!: string[];
    providerChanged: boolean = false;
    genderChanged: boolean = false;
    emailChanged: boolean = false;
    officeEmailChanged: boolean = false;
    degreeChanged: boolean = false;
    organnizationalNpiChanged: boolean = false;
    emrChanged: boolean = false;
    telehealthChanged: boolean = false;    
    languagesChanged: boolean = false;
    hosSurgAffChanged: boolean = false;
    ageRestrictionsChanged: boolean= false;
    isPCPChanged: boolean = false;
    isSpecialistChanged: boolean = false;
    isSdaOfferedChanged: boolean = false;
    providerInfoChanged: boolean = false;
    providerPanelStatusToDisplayChanged: boolean = false;
    providerMedicareStatusToDisplayChanged: boolean = false;
    providerMedicalStatusToDisplayChanged: boolean = false;
    credentialingDateChanged: boolean = false;
    contractInfoChanged: boolean = false;
    specialityInfoChanged: boolean = false;
    mediCalNumChanged: boolean = false;
    medicareNumChanged: boolean = false;
    upinChanged: boolean = false;
    deaChanged: boolean = false;
    stateLicenseChanged: boolean = false;
    licenseExpirationChanged: boolean = false;
    licenseInfoChanged: boolean = false;
    historyBoardChanged: boolean = false;
    locationInfoChanged: boolean = false;
    boardInfoChanged: boolean = false;
    liabilityInfoChanged: boolean = false;
    providerAttestationData$!:IProviderAttestationModel;
    notificationStatusTypes$!:INotificationStatusType[];
    assignToList$!:IProviderAttestationUser[];
    notificationStatus$!:INotificationStatus;
    isSaveEnabled: boolean = false;
    isNotificationStatusValid = true;
    isAssignToValid = true;
    originalNotificationStatus: string = "0";
    originalAssignToUserId: string = "";
    attestationLink: string = "";
    copyright: string = environment.copyright; 
    searchParams! : IProviderSearchModel;
    
    constructor( private fb:FormBuilder,private router: ActivatedRoute, 
      private providerHistoryService: ProviderHistoryService,
      private providerAttestationService: ProviderAttestationService,
      private currentStorageService: CurrentStorageService,
      private toast: ToastService,
      private runtimeConfigLoaderService: RuntimeConfigLoaderService, 
      private route : Router) {
        this.baseUrl =
          this.runtimeConfigLoaderService.getConfigObjectKey('API_URL');
    }

    ngOnInit():void{
        this.providerId = this.router.snapshot.paramMap.get('providerId')!;
        this.preserveSearchParms();
        this.currentStorageService.userName = ' ';
        this.initFormGroup();
        this.getNotificationStatusTypesData(); 
        this.getAssignToListData(); 
        this.getProviderCurrentAndHistoryData(+this.providerId);
        this.getNotificationStatus(+this.providerId);
        this.getProviderAssignToData(+this.providerId);
    }

    saveNotificationStatus(){
      //call api method here to save the assigned to

      //call api method here to save the notification status
      let statusData: IProviderStatus;
      statusData =
        {
          notificationId:0,
          statusTypeId: +this.formGroup.get('ddlNotificationStatus')?.value,
          providerId:+this.providerId, 
          assignedtoid: this.formGroup.get('ddlAssignedTo')?.value.toString()           
        };
        
        this.providerHistoryService.saveProviderNotificationStatus(statusData).subscribe({
          next: async(response :INotificationStatus) => {
            this.notificationStatus$ = response;
            this.formGroup.get("ddlNotificationStatus")?.setValue(this.notificationStatus$.statusTypeId?.toString());
            this.isSaveEnabled = false;
            this.toast.openSnackBar(CONSTANT.MSG_NOTIFICATION_ADD,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
          },
          error: (e) => {
            this.isSaveEnabled = true;
            this.toast.openSnackBar(CONSTANT.MSG_NOTIFICATION_ADD_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
          },
          complete: () => {
            
          }
        });
    }

    disableFormControls(){
      Object.keys(this.formGroup.controls).forEach(key => {
        //if (key !='ddlNotificationStatus' && key!='ddlAssignedTo')
          this.formGroup.controls[key].disable();
      });
    }

    initFormGroup(){
      this.formGroup = this.fb.group({
        ddlAssignedTo: new FormControl(),
        ddlNotificationStatus: new FormControl(),      
        pi_birthdate: ['',{ disabled: true}],
        pi_gender: [''],
        pi_email: [''],
        pi_officeemail: [''],
        pi_degree: [''],   
        pi_organnizationalnpi:[''], 
        pi_emr: [''],
        pi_telehealth:[''], 
        pi_language: [''],  
        pi_hospitalSurgeryCenterAffiliation: [''], 
        pi_ageMin:[''],
        pi_ageMax:[''],  
        pi_chkPCP: new FormControl({disabled: true}),
        pi_chkSpecialty: new FormControl({disabled: true}),
        pi_chksdao: new FormControl({disabled: true}),

        pio_birthdate: ['',{ disabled: true}],
        pio_gender: [''],
        pio_email: [''],
        pio_officeemail: [''],
        pio_degree: [''],          
        pio_organnizationalnpi:[''], 
        pio_emr: [''],
        pio_telehealth:[''],  
        pio_language: [''],   
        pio_hospitalSurgeryCenterAffiliation: [''],                              
        pio_ageMin:[''],
        pio_ageMax:[''],  
        pio_chkPCP: new FormControl({disabled: true}),
        pio_chkSpecialty: new FormControl({disabled: true}),
        pio_chksdao: new FormControl({disabled: true}),

        ///Contract info
        medicalEnrollmentStatus: new FormControl({ disabled: true}), 
        medicareEnrollmentStatus: new FormControl({ disabled: true}),
        panelStatus: new FormControl({ disabled: true}),
        credentialingDate:[''],
        
        medicalEnrollmentStatus_o: new FormControl({ disabled: true}), 
        medicareEnrollmentStatus_o: new FormControl({ disabled: true}),
        panelStatus_o: new FormControl({ disabled: true}),
        credentialingDate_o:[''],
        ///Contract info


         //SECTION 1b
        pl_medicalnumber:[''],
        pl_medicarenumber:[''], 
        //pl_upin:[''],
        pl_dea:[''],
        pl_statelicense:[''],
        pl_statelicenseexpdate:[''],

          //SECTION 1b
        plo_medicalnumber:[''],
        plo_medicarenumber:[''], 
        //plo_upin:[''],
        plo_dea:[''],
        plo_statelicense:[''],
        plo_statelicenseexpdate:[''],

        //License
        mediCalNum:[],
        medicareNum:[],
        //upin:[],
        dea:[],
        stateLicense:[],
        licenseExpiration:[],

        mediCalNum_o:[],
        medicareNum_o:[],
        //upin_o:[],
        dea_o:[],
        stateLicense_o:[],
        licenseExpiration_o:[],

        //board
        boardName:[],
        boardStatus:[],
        effectiveDate:[''],
        expirationDate:[''],

        boardName_o:[],
        boardStatus_o:[],
        effectiveDate_o:[''],
        expirationDate_o:[''],

        //Liabiility
        carrierName:[],
        policyNo: [],
        liabilityExpirationDate: [],
        liabilityAmount:[],

        carrierName_o:[],
        policyNo_o: [],
        liabilityExpirationDate_o: [],
        liabilityAmount_o:[],
      })

      this.formGroup.get('ddlAssignedTo')?.setValue('');
      this.formGroup.get('ddlNotificationStatus')?.setValue("0");
      this.disableFormControls();
    }

    loadFormGroup(){
      this.formGroup.get("pi_birthdate")?.setValue(moment(String(this.currentAndHistoryData$.current.providerDetails.dateOfBirth)).format('MM/DD/YYYY'));      
      this.formGroup.get("pio_birthdate")?.setValue(moment(String(this.currentAndHistoryData$.history.providerDetails.dateOfBirth)).format('MM/DD/YYYY'));
      this.providerChanged =  this.currentAndHistoryData$.current.providerDetails.dateOfBirth != this.currentAndHistoryData$.history.providerDetails.dateOfBirth;

      this.formGroup.get("pi_gender")?.setValue(getGender(this.currentAndHistoryData$.current.providerDetails.gender));
      this.formGroup.get("pio_gender")?.setValue(getGender(this.currentAndHistoryData$.history.providerDetails.gender));      
      this.genderChanged =  this.currentAndHistoryData$.current.providerDetails.gender != this.currentAndHistoryData$.history.providerDetails.gender;

      this.formGroup.get("pi_email")?.setValue(this.currentAndHistoryData$.current.providerDetails.personalEmail);
      this.formGroup.get("pio_email")?.setValue(this.currentAndHistoryData$.history.providerDetails.personalEmail);
      this.emailChanged =  this.currentAndHistoryData$.current.providerDetails.personalEmail != this.currentAndHistoryData$.history.providerDetails.personalEmail;

      this.formGroup.get("pi_officeemail")?.setValue(this.currentAndHistoryData$.current.providerDetails.primaryOfficeEmail);
      this.formGroup.get("pio_officeemail")?.setValue(this.currentAndHistoryData$.history.providerDetails.primaryOfficeEmail);
      this.officeEmailChanged = this.currentAndHistoryData$.current.providerDetails.primaryOfficeEmail != this.currentAndHistoryData$.history.providerDetails.primaryOfficeEmail;

      this.formGroup.get("pi_degree")?.setValue(this.currentAndHistoryData$.current.providerDetails.degree);
      this.formGroup.get("pio_degree")?.setValue(this.currentAndHistoryData$.history.providerDetails.degree);
      this.degreeChanged = this.currentAndHistoryData$.current.providerDetails.degree != this.currentAndHistoryData$.history.providerDetails.degree;

      this.formGroup.get("pi_organnizationalnpi")?.setValue(this.currentAndHistoryData$.current.providerDetails.organizationalNPI);      
      this.formGroup.get("pio_organnizationalnpi")?.setValue(this.currentAndHistoryData$.history.providerDetails.organizationalNPI);
      this.organnizationalNpiChanged = this.currentAndHistoryData$.current.providerDetails.organizationalNPI != this.currentAndHistoryData$.history.providerDetails.organizationalNPI;

      this.formGroup.get("pi_emr")?.setValue(this.currentAndHistoryData$.current.providerDetails.emr);
      this.formGroup.get("pio_emr")?.setValue(this.currentAndHistoryData$.history.providerDetails.emr);
      this.emrChanged = this.currentAndHistoryData$.current.providerDetails.emr != this.currentAndHistoryData$.history.providerDetails.emr;

      this.formGroup.get("pi_telehealth")?.setValue(this.currentAndHistoryData$.current.providerDetails.telehealth);
      this.formGroup.get("pio_telehealth")?.setValue(this.currentAndHistoryData$.history.providerDetails.telehealth);
      this.telehealthChanged = this.currentAndHistoryData$.current.providerDetails.telehealth.trim() != this.currentAndHistoryData$.history.providerDetails.telehealth.trim();

      this.formGroup.get("pi_language")?.setValue(this.currentAndHistoryData$.current.providerDetails.languages);
      this.formGroup.get("pio_language")?.setValue(this.currentAndHistoryData$.history.providerDetails.languages);
      this.languagesChanged = this.currentAndHistoryData$.current.providerDetails.languages != this.currentAndHistoryData$.history.providerDetails.languages;

      this.formGroup.get("pi_hospitalSurgeryCenterAffiliation")?.setValue(this.currentAndHistoryData$.current.providerDetails.hosSurgAff);
      this.formGroup.get("pio_hospitalSurgeryCenterAffiliation")?.setValue(this.currentAndHistoryData$.history.providerDetails.hosSurgAff);
      this.hosSurgAffChanged = this.currentAndHistoryData$.current.providerDetails.hosSurgAff != this.currentAndHistoryData$.history.providerDetails.hosSurgAff;

      this.ageRestrictionsChanged = this.currentAndHistoryData$.current.providerDetails.ageRestrictions != this.currentAndHistoryData$.history.providerDetails.ageRestrictions;
      
      var ageCurrent = this.currentAndHistoryData$.current.providerDetails.ageRestrictions?.split("-");
      var ageHistory = this.currentAndHistoryData$.history.providerDetails.ageRestrictions?.split("-")
      
      this.formGroup.get("pi_ageMin")?.setValue(ageCurrent?.[0] ?? "");   
      this.formGroup.get("pio_ageMin")?.setValue(ageHistory?.[0] ?? "");
      
      this.formGroup.get("pi_ageMax")?.setValue(ageCurrent?.[1] ?? "");
      this.formGroup.get("pio_ageMax")?.setValue(ageHistory?.[1] ?? "");

      this.formGroup.get("pi_chkPCP")?.setValue(this.currentAndHistoryData$.current.providerDetails.isPCP);
      this.formGroup.get("pio_chkPCP")?.setValue(this.currentAndHistoryData$.history.providerDetails.isPCP);
      this.isPCPChanged = this.currentAndHistoryData$.current.providerDetails.isPCP != this.currentAndHistoryData$.history.providerDetails.isPCP;

      this.formGroup.get("pi_chkSpecialty")?.setValue(this.currentAndHistoryData$.current.providerDetails.isSpecialist);
      this.formGroup.get("pio_chkSpecialty")?.setValue(this.currentAndHistoryData$.history.providerDetails.isSpecialist);
      this.isSpecialistChanged = this.currentAndHistoryData$.current.providerDetails.isSpecialist != this.currentAndHistoryData$.history.providerDetails.isSpecialist;

      this.formGroup.get("pi_chksdao")?.setValue(this.currentAndHistoryData$.current.providerDetails.isSdaOffered);
      this.formGroup.get("pio_chksdao")?.setValue(this.currentAndHistoryData$.history.providerDetails.isSdaOffered);

      this.formGroup.get("ddlNotificationStatus")?.setValue((this.notificationStatus$ == null || this.notificationStatus$ == undefined) ? 0 : this.notificationStatus$.statusTypeId );
      this.formGroup.get("ddlAssignedTo")?.setValue((this.assignToList$ == null || this.assignToList$ == undefined) ? 0 : this.originalAssignToUserId );

      this.isSdaOfferedChanged = this.currentAndHistoryData$.current.providerDetails.isSdaOffered != this.currentAndHistoryData$.history.providerDetails.isSdaOffered;

      this.providerInfoChanged = this.providerChanged || this.genderChanged || this.emailChanged || this.officeEmailChanged || this.degreeChanged || this.organnizationalNpiChanged
      || this.emrChanged || this.telehealthChanged || this.languagesChanged || this.hosSurgAffChanged || this.ageRestrictionsChanged || this.isPCPChanged || this.isSpecialistChanged
      || this.isSdaOfferedChanged;


      ///Contract info

      this.providerPanelStatusToDisplay = this.constants$.filter(s => s.constantKey=='PanelStatus').find(
        s=>s.constantValue == (this.currentAndHistoryData$.current.providerDetails.panelStatus == true? "1":"0"))?.constantDesc ?? "";

      this.formGroup.get("medicalEnrollmentStatus")?.setValue(this.providerPanelStatusToDisplay);
      
      this.providerPanelStatusToDisplayHistory = this.constants$.filter(s => s.constantKey=='PanelStatus').find(
        s=>s.constantValue == (this.currentAndHistoryData$.history.providerDetails.panelStatus == true? "1":"0"))?.constantDesc ?? "";

        this.formGroup.get("medicalEnrollmentStatus_o")?.setValue(this.providerPanelStatusToDisplayHistory);
      
      this.providerPanelStatusToDisplayChanged = this.providerPanelStatusToDisplay != this.providerPanelStatusToDisplayHistory;
           
      this.providerMedicareStatusToDisplay = this.constants$.filter(s => s.constantKey=='MedicareStatus').find(
        s=>s.constantValue == (this.currentAndHistoryData$.current.providerDetails.medicareStatus == true? "1":"0"))?.constantDesc ?? "";

      this.formGroup.get("medicareEnrollmentStatus")?.setValue(this.providerMedicareStatusToDisplay);
      
      this.providerMedicareStatusToDisplayHistory = this.constants$.filter(s => s.constantKey=='MedicareStatus').find(
        s=>s.constantValue == (this.currentAndHistoryData$.history.providerDetails.medicareStatus == true? "1":"0"))?.constantDesc ?? "";

      this.formGroup.get("medicareEnrollmentStatus_o")?.setValue(this.providerMedicareStatusToDisplayHistory);

      this.providerMedicareStatusToDisplayChanged = this.providerMedicareStatusToDisplay != this.providerMedicareStatusToDisplayHistory;

      this.providerMedicalStatusToDisplay = this.constants$.filter(s => s.constantKey=='MedicalStatus').find(
        s=>s.constantValue == (this.currentAndHistoryData$.current.providerDetails.medicalStatus == true? "1":"0"))?.constantDesc ?? "";

      this.formGroup.get("panelStatus")?.setValue(this.providerMedicalStatusToDisplay);

      this.providerMedicalStatusToDisplayHistory = this.constants$.filter(s => s.constantKey=='MedicalStatus').find(
          s=>s.constantValue == (this.currentAndHistoryData$.history.providerDetails.medicalStatus == true? "1":"0"))?.constantDesc ?? "";

      this.formGroup.get("panelStatus_o")?.setValue(this.providerMedicalStatusToDisplayHistory);
          
      this.providerMedicalStatusToDisplayChanged = this.providerMedicalStatusToDisplay != this.providerMedicalStatusToDisplayHistory;

      this.formGroup.get("credentialingDate")?.setValue(this.currentAndHistoryData$.current.providerDetails.credentialingDate !=null ? moment(String( this.currentAndHistoryData$.current.providerDetails.credentialingDate)).format('MM/DD/YYYY'):'');
      this.formGroup.get("credentialingDate_o")?.setValue(this.currentAndHistoryData$.history.providerDetails.credentialingDate != null? moment(String( this.currentAndHistoryData$.history.providerDetails.credentialingDate)).format('MM/DD/YYYY'):'');
      
      this.credentialingDateChanged = this.currentAndHistoryData$.current.providerDetails.credentialingDate != this.currentAndHistoryData$.history.providerDetails.credentialingDate;
     
      this.contractInfoChanged = this.credentialingDateChanged || this.providerMedicalStatusToDisplayChanged || this.providerMedicareStatusToDisplayChanged || 
       this.providerPanelStatusToDisplayChanged;

      ///Contract info

      this.formGroup.get("mediCalNum")?.setValue(this.currentAndHistoryData$.current.providerDetails.mediCalNum);
      this.formGroup.get("mediCalNum_o")?.setValue(this.currentAndHistoryData$.history.providerDetails.mediCalNum);
      this.mediCalNumChanged = this.currentAndHistoryData$.current.providerDetails.mediCalNum != this.currentAndHistoryData$.history.providerDetails.mediCalNum;

      this.formGroup.get("medicareNum")?.setValue( this.currentAndHistoryData$.current.providerDetails.medicareNum);
      this.formGroup.get("medicareNum_o")?.setValue( this.currentAndHistoryData$.history.providerDetails.medicareNum);      
      this.medicareNumChanged = this.currentAndHistoryData$.current.providerDetails.medicareNum != this.currentAndHistoryData$.history.providerDetails.medicareNum;
      
      // this.formGroup.get("upin")?.setValue( this.currentAndHistoryData$.current.providerDetails.upin);
      // this.formGroup.get("upin_o")?.setValue( this.currentAndHistoryData$.history.providerDetails.upin);      
      // this.upinChanged = this.currentAndHistoryData$.current.providerDetails.upin != this.currentAndHistoryData$.history.providerDetails.upin;

      this.formGroup.get("dea")?.setValue( this.currentAndHistoryData$.current.providerDetails.dea);
      this.formGroup.get("dea_o")?.setValue( this.currentAndHistoryData$.history.providerDetails.dea);      
      this.deaChanged = this.currentAndHistoryData$.current.providerDetails.dea != this.currentAndHistoryData$.history.providerDetails.dea;

      this.formGroup.get("stateLicense")?.setValue( this.currentAndHistoryData$.current.providerDetails.stateLicense);
      this.formGroup.get("stateLicense_o")?.setValue( this.currentAndHistoryData$.history.providerDetails.stateLicense);      
      this.stateLicenseChanged = this.currentAndHistoryData$.current.providerDetails.stateLicense != this.currentAndHistoryData$.history.providerDetails.stateLicense;

      let licenseExpiration_dt = moment(new Date(String(this.currentAndHistoryData$.current.providerDetails.licenseExpiration) ?? ""));
      this.formGroup.get("licenseExpiration")?.setValue(licenseExpiration_dt.isValid() ? licenseExpiration_dt.format("MM/DD/YYYY") : "");
      let licenseExpiration_o_dt = moment(new Date(String(this.currentAndHistoryData$.history.providerDetails.licenseExpiration) ?? ""));
      this.formGroup.get("licenseExpiration_o")?.setValue(licenseExpiration_o_dt.isValid() ? licenseExpiration_o_dt.format("MM/DD/YYYY") : "");
      this.licenseExpirationChanged =  this.currentAndHistoryData$.current.providerDetails.licenseExpiration != this.currentAndHistoryData$.history.providerDetails.licenseExpiration;

      this.licenseInfoChanged = this.mediCalNumChanged || this.medicareNumChanged || this.upinChanged || 
      this.deaChanged || this.stateLicenseChanged || this.licenseExpirationChanged;

      let board = this.currentAndHistoryData$.current.boardDetails[0];
      let board_o = this.currentAndHistoryData$.history.boardDetails[0];

      this.formGroup.get("boardName")?.setValue(board?.boardName);
      this.formGroup.get("boardName_o")?.setValue(board_o?.boardName);
      this.formGroup.get("boardStatus")?.setValue(board?.boardStatus);
      this.formGroup.get("boardStatus_o")?.setValue(board_o?.boardStatus);
      this.formGroup.get("effectiveDate")?.setValue(board?.effectiveDate ? moment(board?.effectiveDate).format("MM/DD/YYYY") : "");
      this.formGroup.get("effectiveDate_o")?.setValue(board_o?.effectiveDate ? moment(board_o?.effectiveDate).format("MM/DD/YYYY") : "");
      this.formGroup.get("expirationDate")?.setValue(board?.expirationDate ? moment(board?.expirationDate).format("MM/DD/YYYY") : "");
      this.formGroup.get("expirationDate_o")?.setValue(board_o?.expirationDate ? moment(board_o?.expirationDate).format("MM/DD/YYYY"): "");

      this.detectSpecialityChange(this.currentAndHistoryData$.history.specialtyDetails,this.currentAndHistoryData$.current.specialtyDetails);
      this.detectLocationChange(this.currentAndHistoryData$.history.locationDetails,this.currentAndHistoryData$.current.locationDetails);
      this.detectBoardChange(this.currentAndHistoryData$.history.boardDetails,this.currentAndHistoryData$.current.boardDetails);
      this.detectLiabilityChange(this.currentAndHistoryData$.history.insuranceDetails,this.currentAndHistoryData$.current.insuranceDetails);

      //Liability
      let insurance =  this.currentAndHistoryData$.current.insuranceDetails[0];
      let insurance_o =  this.currentAndHistoryData$.history.insuranceDetails[0];

      this.formGroup.get("carrierName")?.setValue(insurance?.carrierName);
      this.formGroup.get("carrierName_o")?.setValue(insurance_o?.carrierName);
      this.formGroup.get("policyNo")?.setValue(insurance?.policyNo);
      this.formGroup.get("policyNo_o")?.setValue(insurance_o?.policyNo);
      this.formGroup.get("liabilityExpirationDate")?.setValue(insurance?.expirationDate ? moment(insurance.expirationDate).format("MM/DD/YYYY") : "");
      this.formGroup.get("liabilityExpirationDate_o")?.setValue(insurance_o?.expirationDate ? moment(insurance_o?.expirationDate).format("MM/DD/YYYY"): "");
      this.formGroup.get("liabilityAmount")?.setValue(insurance.amount);
      this.formGroup.get("liabilityAmount_o")?.setValue(insurance_o.amount);
    }

    getConstants(){
      this.providerAttestationService.getConstants().subscribe({
        next: async (response :IConstant[]) => {
           this.constants$ = response;  
           this.loadFormGroup();            
        },
        error: (e) => { 
          this.isLoading=false;
        },
        complete: () => {
          this.isLoading=true;
        }
      });
     }
    
    getProviderCurrentAndHistoryData(providerId: number){
      this.providerHistoryService.getProviderHistoryDetails(providerId).subscribe({
          next: async (response :IProviderHistoryModel) => {
            this.currentAndHistoryData$ = response;
            this.getproviderAttestationData(providerId);                
            this.getConstants();
          },
          error: (e) => {
            console.error('Provider History request failed with error - ' + e);
            this.isLoading=false;
          },
          complete: () => {
            console.info('Request completed !!!') 
            this.isLoading=true;
          }
        });
    } 
  
    specialityChanged(specialities: IProviderSpecialtyModel[],speciality: string): any {    
      if(specialities.filter(x=>x.specialty == speciality).length == 0){
        return true;        
      }
      return false;      
    }

    boardTrainingChanged(board: IProviderBoard): boolean {
      var historyBoard = this.currentAndHistoryData$.history.boardDetails.find(x=>x.boardName == board.boardName && x.effectiveDate == board.effectiveDate
        && x.expirationDate == board.expirationDate && x.boardStatus == board.boardStatus);
      
      if(historyBoard == null)
        return true;

      return false;
    }

    liabilityChanged(insurance: IProviderInsurance): any {
      var insuranceDetail = this.currentAndHistoryData$.history.insuranceDetails.find(
        x=>x.carrierName == insurance.carrierName && x.amount == insurance.amount && x.expirationDate == insurance.expirationDate 
        && x.policyNo == insurance.policyNo);
      
      if(insuranceDetail == null)
        return true;

      return false;
    }
      
    locationChanged(n: IProviderOfficeInformationModel): boolean {
      if(n.streetAddress1 == null || n.streetAddress1 == "" )
        return false;

      var locationDetail = this.currentAndHistoryData$.history.locationDetails.find(o=>o.city == n.city && o.fax == n.fax && o.phone == n.phone 
        && o.state == n.state && o.streetAddress1 == n.streetAddress1 && o.streetAddress2 == n.streetAddress2 && o.zip == n.zip);

      if(locationDetail == null)
        return true;
      else
        return false;
    }

    getproviderAttestationData(providerId : number){
      //get the provider attestation details here
      this.providerHistoryService.getproviderAttestationData(providerId).subscribe({
        next: (response :IProviderAttestationModel) => {
          //console.log(response);
          this.providerAttestationData$ = response;                   
        },
        error: (error:any) => {
          console.error('Provider attestation details request failed with error - ' + error);
        },
        complete: () => {          
          console.info('Provider attestation details request completed !!!')           
        }
      });
     }
  
    detectSpecialityChange(oldValues: IProviderSpecialtyModel[], newValues: IProviderSpecialtyModel[]) {      
      if(newValues?.length != oldValues.length) {
        this.specialityInfoChanged = true;
        return;
      }
      
      newValues.forEach(n=> {
        if(oldValues.find(o=>o.specialty == n.specialty) == null)
          this.specialityInfoChanged = true;
          return;
      })        
    }

    detectLocationChange(oldValues: IProviderOfficeInformationModel[], newValues: IProviderOfficeInformationModel[]) {
      if(newValues?.length != oldValues.length) {
        this.locationInfoChanged = true;
        return;
      }
      
      newValues.forEach(n=> {
        if(oldValues.find(o=>o.city == n.city && o.fax == n.fax && o.phone == n.phone 
          && o.state == n.state && o.streetAddress1 == n.streetAddress1 && o.streetAddress2 == n.streetAddress2 && o.zip == n.zip  ) == null)
          this.locationInfoChanged = true;
          return;
      })
    }

    detectBoardChange(oldValues: IProviderBoard[], newValues: IProviderBoard[]) {
      if(newValues?.length != oldValues.length) {
        this.boardInfoChanged = true;
        return;
      }
      
      newValues.forEach(n=> {
        if(oldValues.find(o=>o.boardName == n.boardName && o.effectiveDate == n.effectiveDate && 
          o.expirationDate == n.expirationDate && o.boardStatus == n.boardStatus) == null)
          this.boardInfoChanged = true;
          return;
      })
    }
  
    detectLiabilityChange(oldValues: IProviderInsurance[], newValues: IProviderInsurance[]) {
      if(newValues?.length != oldValues.length) {
        this.liabilityInfoChanged = true;
        return;
      }
      
      newValues.forEach(n=> {
        if(oldValues.find(o=>o.amount == n.amount && o.carrierName == n.carrierName && 
          o.expirationDate == n.expirationDate && o.policyNo == n.policyNo ) == null)
          this.liabilityInfoChanged = true;
          return;
      })
    }      

    OpenOfficeHoursModal(){
      const addOfficeHoursModal = this.addOfficeHoursModal.open().subscribe(async (_) => {  
         //await this.initAddOfficeHoursForm.next(true);
         await addOfficeHoursModal.unsubscribe();         
      });
    }

    locationTypeChanged(location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;           
      return false;
    }

    streetAddress1Changed(location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);      
      if(historyLocation == null)
        return true;
      else if(historyLocation.streetAddress1?.trim() != location.streetAddress1?.trim())
        return true;
      return false;
    }

    streetAddress2Changed(location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;
      else if(historyLocation.streetAddress2 != location.streetAddress2)
        return true;
      return false;
    }

    cityChanged (location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;
      else if(historyLocation.city != location.city)
        return true;
      return false;
    }

    stateChanged (location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;
      else if(historyLocation.state != location.state)
        return true;
      return false;
    }

    zipChanged (location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;
      else if(historyLocation.zip != location.zip)
        return true;
      return false;
    }

    phoneChanged (location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;
      else if(historyLocation.phone != location.phone)
        return true;
      return false;
    }

    faxChanged (location: IProviderOfficeInformationModel): any {
      var historyLocation = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationType == location.locationType);
      if(historyLocation == null)
        return true;
      else if(historyLocation.fax != location.fax)
        return true;
      return false;
    }

    officeHoursChanged(location: IProviderOfficeInformationModel): any {
        var currentData = this.currentAndHistoryData$.current.locationDetails.find(x=>x.locationId == location.locationId);
        var historyData = this.currentAndHistoryData$.history.locationDetails.find(x=>x.locationId == location.locationId);

        if(currentData?.monHours != historyData?.monHours)
          return true;
        if(currentData?.tuesHours != historyData?.tuesHours)
          return true;
        if(currentData?.wedHours != historyData?.wedHours)
          return true;
        if(currentData?.thursHours != historyData?.thursHours)
          return true;
        if(currentData?.friHours != historyData?.friHours)
          return true;
        if(currentData?.satHours != historyData?.satHours)
          return true;
        if(currentData?.sunHours != historyData?.sunHours)
          return true;

        return false;
    }
      
    viewOfficeHoursModal(id:number, history:boolean){
      this.showReadonlyModal=true;      
      this.modalwidth='350px';
      this.addOfficeHourComponent.officeHoursFormAction='View';
      let providerOfficeInformationData = this.currentAndHistoryData$.current.locationDetails;  

      if(history)
      {
        providerOfficeInformationData = this.currentAndHistoryData$.history.locationDetails;  
      }
      
      let index = providerOfficeInformationData.findIndex((obj => obj.locationId == id));

      this.addOfficeHourComponent.locations$ = providerOfficeInformationData;
      this.addOfficeHourComponent.selectedLocationId = id;          

      if (index != -1)
      {         
        let officeTime = providerOfficeInformationData[index];  
        this.addOfficeHourComponent.monOfficeHrs =(officeTime.monHours !=null && officeTime.monHours!=undefined) ? CommonUtil.convertTime(officeTime.monHours):'00:00';
        this.addOfficeHourComponent.tueOfficeHrs =(officeTime.tuesHours !=null && officeTime.tuesHours!=undefined) ? CommonUtil.convertTime(officeTime.tuesHours):'00:00';
        this.addOfficeHourComponent.wedOfficeHrs =(officeTime.wedHours !=null && officeTime.wedHours!=undefined) ? CommonUtil.convertTime(officeTime.wedHours):'00:00';
        this.addOfficeHourComponent.thuOfficeHrs =(officeTime.thursHours !=null && officeTime.thursHours!=undefined) ? CommonUtil.convertTime(officeTime.thursHours):'00:00';
        this.addOfficeHourComponent.friOfficeHrs =(officeTime.friHours !=null && officeTime.friHours!=undefined) ? CommonUtil.convertTime(officeTime.friHours):'00:00';
        this.addOfficeHourComponent.satOfficeHrs =(officeTime.satHours !=null && officeTime.satHours!=undefined) ? CommonUtil.convertTime(officeTime.satHours):'00:00';
        this.addOfficeHourComponent.sunOfficeHrs =(officeTime.sunHours !=null && officeTime.sunHours!=undefined) ? CommonUtil.convertTime(officeTime.sunHours):'00:00'; 
      }      
      this.OpenOfficeHoursModal();               
    }

    getNotificationStatusTypesData(){
      //get the list of notification status types
      this.providerHistoryService.getNotificationStatusTypes().subscribe({
        next: async (response :INotificationStatusType[]) => {
          this.notificationStatusTypes$ = response; 
          // sort status types
          this.notificationStatusTypes$.sort((a, b) => a.statusTypeName!.localeCompare(b.statusTypeName!))
          // add default status type
          let status: INotificationStatusType = {statusTypeId: 0, statusTypeName: "Select", isDeleted:false, createdBy:"", createdDate:moment.utc( new Date ).format() };
          this.notificationStatusTypes$.unshift(status);
        },
        error: (error:any) => {
          console.error('Notification status types request failed with error - ' + error);
        },
        complete: () => {          
          console.info('Notification status types request completed !!!')        
        }
      });
    }
    getAssignToListData(){
      //get the list of notification status types
      this.providerHistoryService.getAssignToData().subscribe({
        next: async (response :IProviderAttestationUser[]) => {
          this.assignToList$ = response; 
          // sort status types
          this.assignToList$.sort((a, b) => a.firstName!.localeCompare(b.firstName!))
          // add default status type
          let usr: IProviderAttestationUser = {providerAttestationUserId: "", firstName: "Select", disabled:false, lastName:"", email:"",emailVerified:false,mSIdUserId:"",oneHealthcareIdUserId:"",providerAttestationRoleId:0, userName:"" };
          this.assignToList$.unshift(usr);
        },
        error: (error:any) => {
          console.error('Assign To data request failed with error - ' + error);
        },
        complete: () => {          
          console.info('Assign To data request completed !!!')        
        }
      });
    }

    getNotificationStatus(providerId:number){
        //get the provider's notification status
        this.providerHistoryService.getNotificationStatusByProviderId(providerId).subscribe({
          next: async (response :INotificationStatus) => {
            this.notificationStatus$ = response;
            if (this.notificationStatus$ != null && this.notificationStatus$ != undefined)
            {
              this.originalNotificationStatus = this.notificationStatus$.statusTypeId?.toString() ?? "0";
              this.formGroup.get('ddlNotificationStatus')?.setValue(this.originalNotificationStatus);
            }
          },
          error: (error:any) => {
            console.error('Get notification status request failed with error - ' + error);
          },
          complete: () => {          
            console.info('Get notification status request completed!!!');  
          }
        });
     }

     getProviderAssignToData(providerId:number){
      //get the provider's notification status

      this.providerHistoryService.getProviderDataByProviderId(providerId).subscribe({
        next: async (response :IProviderModel) => {
          if (response != null && response != undefined)
          {
            this.originalAssignToUserId = response.assignedToUserId ?? "" ;
            this.formGroup.get('ddlAssignedTo')?.setValue(this.originalAssignToUserId);
          }
        },
        error: (error:any) => {
          console.error('Get Assign To request failed with error - ' + error);
        },
        complete: () => {          
          console.info('Get Assign To request completed!!!');  
        }
      });
   }


     editNotificationStatus(){
      this.originalNotificationStatus = this.formGroup.get('ddlNotificationStatus')?.value;
      this.originalAssignToUserId = this.formGroup.get('ddlAssignedTo')?.value;
      this.isSaveEnabled = true;
    }
   
    cancelNotificationStatus(){
      this.formGroup.get('ddlNotificationStatus')?.setValue(this.originalNotificationStatus);
      this.formGroup.get('ddlAssignedTo')?.setValue(this.originalAssignToUserId);
      this.isSaveEnabled = false;
      this.isNotificationStatusValid = true;
      this.isAssignToValid = true;
    }

    SaveOrUpdateNotification()
    {
      // validate
      this.isNotificationStatusValid = true;
      this.isAssignToValid = true;
      if (this.formGroup.get('ddlNotificationStatus')?.value == "0")
      {
        this.isNotificationStatusValid = false;
        return;
      } 

      if (this.formGroup.get('ddlAssignedTo')?.value == "")
      {
        this.isAssignToValid = false;
        return;
      } 

      if (this.originalNotificationStatus == null || this.originalNotificationStatus == undefined || this.originalNotificationStatus == "0")
      {
        this.saveNotificationStatus();
      }
      else
      {
        this.updateNotificationStatus();
      }
    }
    
    updateNotificationStatus(){
      // update notification status

      //call api method here to save the assigned to - TBD

      //call api method here to save the notification status
        
          let statusData: IProviderStatus;
          statusData =
            {
              notificationId: this.notificationStatus$.notificationId,
              statusTypeId: +this.formGroup.get('ddlNotificationStatus')?.value,
              providerId:+this.providerId, 
              assignedtoid: this.formGroup.get('ddlAssignedTo')?.value.toString()           
            };
            
          this.providerHistoryService.updateProviderNotificationStatus(statusData).subscribe({
            next: async(response :INotificationStatus) => {
              this.notificationStatus$ = response;
              this.isSaveEnabled = false;
              this.formGroup.get('ddlNotificationStatus')?.disable();
              this.toast.openSnackBar(CONSTANT.MSG_NOTIFICATION_UPDATE,'OK',ENUM.MESSAGE_TYPE.SUCCESS);
            },
            error: (e) => {
              this.toast.openSnackBar(CONSTANT.MSG_NOTIFICATION_UPDATE_ERROR,'OK',ENUM.MESSAGE_TYPE.ERROR);
            },
            complete: () => {
            }
        });
    }
    getFaxPDF(){
      //get the provider's notification status
      var attestationPeriod = this.currentAndHistoryData$.current.providerDetails.attestationPeriod
      this.providerHistoryService.getFaxPDFByProviderId(+this.providerId,attestationPeriod).subscribe({
        next: async (response : Blob) => {
          const newBlob = new Blob([response],{type: "application/pdf"})
          const data = window.URL.createObjectURL(newBlob);
          window.open(data);
          //const link = document.createElement("a");
          //link.href = data; 
          //link.click();
        },
        error: (error:any) => {
          console.error('Get notification status request failed with error - ' + error);
        },
        complete: () => {          
          console.info('Get notification status request completed!!!');  
        }
      });
   }
 
   getAttestationLink(){
     //get the provider's notification status
      var labelConfirm = document.getElementById("lblURLCopied");
      if (labelConfirm){
        labelConfirm.innerText = "";
      }
     this.providerHistoryService.getAttestationLinkByProviderId(+this.providerId).subscribe({
       next: async (response :string) => {
         if (response != null && response != "")
         {
          this.attestationLink =  window.location.protocol + "//" + window.location.hostname + '/login/' + response;
         }
         else 
         {
          this.attestationLink = "Registration link could not be generated because of data exception";
         }
       },
       error: (error:any) => {
         console.error('Get notification status request failed with error - ' + error);
       },
       complete: () => {          
         console.info('Get notification status request completed!!!');  
       }
     });
     
     this.OpenAttestationLinkModal();
  }

  OpenAttestationLinkModal(){
    const attestationLinkModal = this.attestationLinkModal.open().subscribe(async (_) => {
      await attestationLinkModal.unsubscribe();
    });
  }

  copyURLtoClipboard(){
    var label = document.getElementById("lblAttestationURL");

    if (label){
      navigator.clipboard.writeText(label.innerText);
      var labelConfirm = document.getElementById("lblURLCopied");
      if (labelConfirm)
        {
        labelConfirm.innerText = "URL copied to clipboard";
      }
    }
  }
  convertTime(time?:string)
    {
      let retValue:string ='';
      if (time !=null && time !=undefined){
        if(time!=='' && time.includes('-'))
        {
          retValue= CommonUtil.standardTimeFormat(CommonUtil.getParseData(time,true)) + '-' + CommonUtil.standardTimeFormat(CommonUtil.getParseData(time,false));
        }
        else if (time == 'Closed')
          retValue =time;
      }
      //console.log(retValue);
      return retValue;
    }

    //to hold search parms data coming from list page
    preserveSearchParms()
    {
      const searchParmsState = this.route.getCurrentNavigation();
      if(searchParmsState && searchParmsState.extras.state) 
      {
        this.searchParams = searchParmsState.extras.state as IProviderSearchModel
      } 
    }
    
}

function getGender(gender: string | undefined): any {
  if(gender?.trim() == "M")
    return "Male";
  else if(gender?.trim() == "F")
    return "Female";
  else
    return "";
}

