import { Component, EventEmitter, Input, OnInit, Output,OnChanges, ViewChild, ElementRef,AfterViewInit,AfterContentInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { TextValidators } from '../../../../shared/validators/text.validator';
import { IProviderOfficeInformationModel } from 'src/app/app-store/entities/provider';
import { IRange } from 'src/app/app-store/entities/formfields';

@Component({
    selector: 'app-add-office-hour',
    templateUrl: './add-Office-hour.component.html',
    styleUrls: ['./add-Office-hour.component.scss'],
    standalone: false
})
export class AddOfficeHourComponent implements OnInit {
  @ViewChild('chkoffice_Mon', { static: false })
  chkoffice_Mon!: ElementRef;

  @ViewChild('chkoffice_Tue', { static: false })
  chkoffice_Tue!: ElementRef;

  @ViewChild('chkoffice_Wed', { static: false })
  chkoffice_Wed!: ElementRef;

  @ViewChild('chkoffice_Thu', { static: false })
  chkoffice_Thu!: ElementRef;

  @ViewChild('chkoffice_Fri', { static: false })
  chkoffice_Fri!: ElementRef;

  @ViewChild('chkoffice_Sat', { static: false })
  chkoffice_Sat!: ElementRef;

  @ViewChild('chkoffice_Sun', { static: false })
  chkoffice_Sun!: ElementRef;

  @Input() formGroup!: FormGroup;
  @Output() validate = new EventEmitter();
  submitted: boolean = false;
  
  @Input() onInit!: Observable<any>;
  @Input() isReadonly!: boolean;
  public locationDetails$! : IProviderOfficeInformationModel[];
  public selectedLocationId? : Number;
  public officeHoursFormAction!:string;
  public officeHours: IRange[] =[];
  public resetForm:boolean=false;
  public isMonClose:boolean=false;
  public isTueClose:boolean=false;
  public isWedClose:boolean=false;
  public isThuClose:boolean=false;
  public isFriClose:boolean=false;
  public isSatClose:boolean=false;
  public isSunClose:boolean=false;

  public isValidMonRange:boolean=true;
  public isValidTueRange:boolean=true;
  public isValidWedRange:boolean=true;
  public isValidThuRange:boolean=true;
  public isValidFriRange:boolean=true;
  public isValidSatRange:boolean=true;
  public isValidSunRange:boolean=true;
  public isSameAsMonday: boolean = false;
  public isMonToValue: boolean = false;

  locations$!: IProviderOfficeInformationModel[];
  monOfficeHrs!: string;
  tueOfficeHrs!: string;
  wedOfficeHrs!: string;
  thuOfficeHrs!: string;
  friOfficeHrs!: string;
  satOfficeHrs!: string;
  sunOfficeHrs!: string;
  
  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      office_MonToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_MonFromHour: ['',[Validators.required, TextValidators.notEmpty]],  
      office_TueToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_TueFromHour: ['',[Validators.required, TextValidators.notEmpty]],
      office_WedToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_WedFromHour: ['',[Validators.required, TextValidators.notEmpty]],   
      office_ThuToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_ThuFromHour: ['',[Validators.required, TextValidators.notEmpty]], 
      office_FriToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_FriFromHour: ['',[Validators.required, TextValidators.notEmpty]], 
      office_SatToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_SatFromHour: ['',[Validators.required, TextValidators.notEmpty]], 
      office_SunToHour: ['',[Validators.required, TextValidators.notEmpty]],          
      office_SunFromHour: ['',[Validators.required, TextValidators.notEmpty]], 
      chkoffice_Mon: new FormControl(),
      chkoffice_Tue: new FormControl(),
      chkoffice_Wed: new FormControl(),
      chkoffice_Thu: new FormControl(),
      chkoffice_Fri: new FormControl(),
      chkoffice_Sat: new FormControl(),
      chkoffice_Sun: new FormControl(),       
    });

    //this.setOfficeHours();
    // this.onInit.subscribe((result) => {
    //   if (result) {   
    //     // if(this.resetForm)
    //     // {
    //     //   this.formGroup.reset(); 
    //     //   this.resetForm=false;
    //     // }
    //   }
    // });

    this.formGroup.valueChanges.subscribe((_) => this.validate.emit(this.formGroup));

    this.formGroup.get('office_MonToHour')?.valueChanges.subscribe((_) => this.checkMonValueChanged());
    this.formGroup.get('office_MonFromHour')?.valueChanges.subscribe((_) => this.checkMonValueChanged());
  }
  
  ngAfterViewInit() {
   
  }
  ngAfterContentInit() {
    
  }
  // setOfficeHours()
  // {   
  //   //console.log(this.officeHours);
  //   if(this.officeHours !=null && this.officeHours != undefined)
  //   {
  //     if (this.officeHours.length > 0)
  //     {
  //       for (var i = 0; i < this.officeHours.length; i++) {          
  //       switch (this.officeHours[i].key)
  //       {
  //         case 'officeMonhrs':
  //         this.formGroup.get('office_MonToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_MonFromHour')?.setValue(this.officeHours[i].max);
  //         this.monOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //         case 'officeTuehrs':
  //         this.formGroup.get('office_TueToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_TueFromHour')?.setValue(this.officeHours[i].max);
  //         this.tueOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //         case 'officeWedhrs':
  //         this.formGroup.get('office_WedToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_WedFromHour')?.setValue(this.officeHours[i].max);
  //         this.wedOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //         case 'officeThuhrs':
  //         this.formGroup.get('office_ThuToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_ThuFromHour')?.setValue(this.officeHours[i].max);
  //         this.thuOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //         case 'officeFrihrs':
  //         this.formGroup.get('office_FriToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_FriFromHour')?.setValue(this.officeHours[i].max);
  //         this.friOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //         case 'officeSathrs':
  //         this.formGroup.get('office_SatToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_SatFromHour')?.setValue(this.officeHours[i].max);
  //         this.satOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //         case 'officeSunhrs':
  //         this.formGroup.get('office_SunToHour')?.setValue(this.officeHours[i].min);
  //         this.formGroup.get('office_SunFromHour')?.setValue(this.officeHours[i].max);
  //         this.sunOfficeHrs =this.officeHours[i].min + ' - ' +  this.officeHours[i].max;
  //         break;
  //       }          
  //     }
  //   }
  //   }
  // }
  ngOnChanges(changes: any) {   
   
  }

  getOfficeHours(data:any,isMinValue:boolean){
    let officehrs:any;
    let retValue: string='';
    if(data != null && data!=undefined){
      if (data!='')
      {
        if(data.includes('-'))
        {
          officehrs = data.split('-');
          if (officehrs!=null && officehrs != undefined){
            if (officehrs.length >0 && isMinValue == false)
              retValue= officehrs[1];           
            else            
              retValue= officehrs[0];                      
          }          
        }
        else if (data.toLowerCase() != 'closed')
          retValue = data;
      }  
    }
    return retValue;    
  }

  closedOfficeHoursCheck(value:any,day:string){
     //console.log( value.target.value);
    switch(day){
      case "Mon":       
          this.isMonClose=this.chkoffice_Mon.nativeElement.checked
          if(this.chkoffice_Mon.nativeElement.checked)           
          {
            this.formGroup.get('office_MonToHour')?.setValue("00:00");
          this.formGroup.get('office_MonFromHour')?.setValue("00:00"); 
          }
          else
          {
            this.formGroup.get('office_MonToHour')?.setValue("");
            this.formGroup.get('office_MonFromHour')?.setValue(""); 
          }          
      break;
      case "Tue":       
          this.isTueClose=this.chkoffice_Tue.nativeElement.checked 
          if(this.chkoffice_Tue.nativeElement.checked)           
          {
          this.formGroup.get('office_TueToHour')?.setValue("00:00");  
          this.formGroup.get('office_TueFromHour')?.setValue("00:00");   
          }
          else
          {
            this.formGroup.get('office_TueToHour')?.setValue("");
            this.formGroup.get('office_TueFromHour')?.setValue(""); 
          }
      break;
      case "Wed":       
          this.isWedClose=this.chkoffice_Wed.nativeElement.checked  
          if(this.chkoffice_Wed.nativeElement.checked)           
          {
          this.formGroup.get('office_WedToHour')?.setValue("00:00");  
          this.formGroup.get('office_WedFromHour')?.setValue("00:00");        
          }
          else
          {
            this.formGroup.get('office_WedToHour')?.setValue("");
            this.formGroup.get('office_WedFromHour')?.setValue(""); 
          }
      break;
      case "Thu":       
          this.isThuClose=this.chkoffice_Thu.nativeElement.checked
          if(this.chkoffice_Thu.nativeElement.checked)           
          {
          this.formGroup.get('office_ThuToHour')?.setValue("00:00");  
          this.formGroup.get('office_ThuFromHour')?.setValue("00:00");        
          }
          else
          {
            this.formGroup.get('office_ThuToHour')?.setValue("");
            this.formGroup.get('office_ThuFromHour')?.setValue(""); 
          }
      break;
      case "Fri":       
          this.isFriClose=this.chkoffice_Fri.nativeElement.checked
          if(this.chkoffice_Fri.nativeElement.checked)           
          {
          this.formGroup.get('office_FriToHour')?.setValue("00:00");  
          this.formGroup.get('office_FriFromHour')?.setValue("00:00");      
          }
          else
          {
            this.formGroup.get('office_FriToHour')?.setValue("");
            this.formGroup.get('office_FriFromHour')?.setValue(""); 
          }
      break;
      case "Sat":       
          this.isSatClose=this.chkoffice_Sat.nativeElement.checked
          if(this.chkoffice_Sat.nativeElement.checked)           
          {
          this.formGroup.get('office_SatToHour')?.setValue("00:00");  
          this.formGroup.get('office_SatFromHour')?.setValue("00:00");   
          }
          else
          {
            this.formGroup.get('office_SatToHour')?.setValue("");
            this.formGroup.get('office_SatFromHour')?.setValue(""); 
          }
      break;
      case "Sun":       
          this.isSunClose=this.chkoffice_Sun.nativeElement.checked
          if(this.chkoffice_Sun.nativeElement.checked)           
          {
          this.formGroup.get('office_SunToHour')?.setValue("00:00");  
          this.formGroup.get('office_SunFromHour')?.setValue("00:00");   
          }
          else
          {
            this.formGroup.get('office_SunToHour')?.setValue("");
            this.formGroup.get('office_SunFromHour')?.setValue(""); 
          }
      break;
    }
  }

  validateDayRange(field:any,day:string){
    if(field.value !='')
    {
      switch(day){
        case "Mon":
          if (parseInt(this.formGroup.get('office_MonFromHour')?.value)< parseInt(this.formGroup.get('office_MonToHour')?.value)) 
          {
            this.formGroup.get('office_MonToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidMonRange= false;                
          } 
          else
          {
            if (!this.formGroup.get('office_MonToHour')?.hasError('empty'))
              this.formGroup.get('office_MonToHour')?.setErrors(null); 
            this.isValidMonRange= true;

            if(this.formGroup.get('office_MonFromHour')?.value !='')
                this.isMonToValue=true;
          }
          
        break;
        case "Tue":
          if (parseInt(this.formGroup.get('office_TueFromHour')?.value)< parseInt(this.formGroup.get('office_TueToHour')?.value))       
          {
            this.formGroup.get('office_TueToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidTueRange= false;                
          }            
          else
          {
            if (!this.formGroup.get('office_TueToHour')?.hasError('empty'))
              this.formGroup.get('office_TueToHour')?.setErrors(null); 
            this.isValidTueRange= true;
          }
        break;
        case "Wed":
          if (parseInt(this.formGroup.get('office_WedFromHour')?.value)< parseInt(this.formGroup.get('office_WedToHour')?.value))       
          {
            this.formGroup.get('office_WedToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidWedRange= false;                
          }
          else
          {
            if (!this.formGroup.get('office_WedToHour')?.hasError('empty'))
              this.formGroup.get('office_WedToHour')?.setErrors(null); 
            this.isValidWedRange= true;
          }
        break;
        case "Thu":
          if (parseInt(this.formGroup.get('office_ThuFromHour')?.value)< parseInt(this.formGroup.get('office_ThuToHour')?.value))       
          {
            this.formGroup.get('office_ThuToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidThuRange= false;                
          }
          else
          {
            if (!this.formGroup.get('office_ThuToHour')?.hasError('empty'))
              this.formGroup.get('office_ThuToHour')?.setErrors(null); 
            this.isValidThuRange= true;
          }
        break;
        case "Fri":
          if (parseInt(this.formGroup.get('office_FriFromHour')?.value)< parseInt(this.formGroup.get('office_FriToHour')?.value))       
          {
            this.formGroup.get('office_FriToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidFriRange= false;                
          }
          else
          {
            if (!this.formGroup.get('office_FriToHour')?.hasError('empty'))
              this.formGroup.get('office_FriToHour')?.setErrors(null); 
            this.isValidFriRange= true;
          }
        break;
        case "Sat":
          if (parseInt(this.formGroup.get('office_SatFromHour')?.value)< parseInt(this.formGroup.get('office_SatToHour')?.value))       
          {
            this.formGroup.get('office_SatToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidSatRange= false;                
          }
          else
          {
            if (!this.formGroup.get('office_SatToHour')?.hasError('empty'))
              this.formGroup.get('office_SatToHour')?.setErrors(null); 
            this.isValidSatRange= true;
          }
        break;
        case "Sun":
          if (parseInt(this.formGroup.get('office_SunFromHour')?.value)< parseInt(this.formGroup.get('office_SunToHour')?.value))       
          {
            this.formGroup.get('office_SunToHour')?.setErrors({'InvalidRange':true}); 
            this.isValidSunRange= false;                
          }
          else
          {
            if (!this.formGroup.get('office_SunToHour')?.hasError('empty'))
              this.formGroup.get('office_SunToHour')?.setErrors(null); 
            this.isValidSunRange= true;
          }
        break;
      }     
    }      
  }

  applyMondayHoursToAll(event: any) {
    this.isSameAsMonday = event.target.checked;
    let sunToHour = this.formGroup.get('office_SunToHour')?.value;
    let sunFromHour = this.formGroup.get('office_SunFromHour')?.value;
    let satToHour = this.formGroup.get('office_SatToHour')?.value;
    let satFromHour = this.formGroup.get('office_SatFromHour')?.value;

    if ((sunToHour == '' || sunToHour == null) && (sunFromHour == '' || sunFromHour == null)) {
      this.chkoffice_Sun.nativeElement.checked = this.isSameAsMonday;
      this.isSunClose = this.isSameAsMonday;

      if (this.chkoffice_Sun.nativeElement.checked) {
        this.formGroup.get('office_SunToHour')?.setValue("00:00");
        this.formGroup.get('office_SunFromHour')?.setValue("00:00");
      }
      else {
        this.formGroup.get('office_SunToHour')?.setValue("");
        this.formGroup.get('office_SunFromHour')?.setValue("");
      }
    }

    if ((satToHour == '' || satToHour == null) && (satFromHour == '' || satFromHour == null)) {
      this.chkoffice_Sat.nativeElement.checked = this.isSameAsMonday;
      this.isSatClose = this.isSameAsMonday;

      if (this.chkoffice_Sat.nativeElement.checked) {
        this.formGroup.get('office_SatToHour')?.setValue("00:00");
        this.formGroup.get('office_SatFromHour')?.setValue("00:00");
      }
      else {
        this.formGroup.get('office_SatToHour')?.setValue("");
        this.formGroup.get('office_SatFromHour')?.setValue("");
      }
    }

    this.formGroup.get('office_TueToHour')?.setValue(this.formGroup.get('office_MonToHour')?.value);
    this.formGroup.get('office_TueFromHour')?.setValue(this.formGroup.get('office_MonFromHour')?.value);

    this.formGroup.get('office_WedToHour')?.setValue(this.formGroup.get('office_MonToHour')?.value);
    this.formGroup.get('office_WedFromHour')?.setValue(this.formGroup.get('office_MonFromHour')?.value);

    this.formGroup.get('office_ThuToHour')?.setValue(this.formGroup.get('office_MonToHour')?.value);
    this.formGroup.get('office_ThuFromHour')?.setValue(this.formGroup.get('office_MonFromHour')?.value);

    this.formGroup.get('office_FriToHour')?.setValue(this.formGroup.get('office_MonToHour')?.value);
    this.formGroup.get('office_FriFromHour')?.setValue(this.formGroup.get('office_MonFromHour')?.value);
  }

  checkMonValueChanged() {
    this.isSameAsMonday = true;
    if (this.formGroup.get('office_MonToHour')?.value != null && this.formGroup.get('office_MonFromHour')?.value != null) {
        this.isSameAsMonday = false;
      }   
  }
}
