import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-provider-layout',
    templateUrl: './provider-admin-layout.component.html',
    styleUrls: ['./provider-admin-layout.component.scss'],
    standalone: false
})
export class ProviderAdminLayoutComponent implements OnInit {

  constructor() { }

  copyright: string = environment.copyright; 
  
  ngOnInit(): void {
  }

}
