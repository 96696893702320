import { ElementSchemaRegistry } from '@angular/compiler';
import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { Observable, of, switchMap, concatMap, map, forkJoin } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly router = inject(Router);
  forbiddenUrl: string = "/forbidden";
  isPdiUser: boolean = false;
  isPdiAdmin: boolean = false;
  
  constructor(private runtimeConfigLoaderService: RuntimeConfigLoaderService) {}

  isAuthenticatedUser(): Observable<boolean> | Observable<UrlTree> {
    return this.checkUserAuthenticated().pipe(
      switchMap((isAuthenticated) => {
        var retval: any = this.router.parseUrl(this.forbiddenUrl);
        if (isAuthenticated) {
          retval = true;
        }
        return of(retval)
      })
    );
  }

  isPdiAdminRole(): Observable<boolean> | Observable<UrlTree> {
    return this.checkUserAuthenticated().pipe(
      switchMap((isAuthenticated) => {
        var retval: any = this.router.parseUrl(this.forbiddenUrl);
        if (isAuthenticated && this.isPdiAdmin) {
          retval = true;
        }
        return of(retval)
      })
    );
  }

  isPdiUserRole(): Observable<boolean> | Observable<UrlTree> {
    return this.checkUserAuthenticated().pipe(
      switchMap((isAuthenticated) => {
        var retval: any = this.router.parseUrl(this.forbiddenUrl);
        if (isAuthenticated && (this.isPdiAdmin || this.isPdiUser)) {
          retval = true;
        }
        return of(retval)
      })
    );
  }

  checkUserAuthenticated(): Observable<boolean> {
    return forkJoin([this.oidcSecurityService.isAuthenticated(), this.oidcSecurityService.getPayloadFromAccessToken()]).pipe(map(([isAuthenticated, payload]) => {
      if (isAuthenticated) {
        let roles = payload.roles;
        let env = this.runtimeConfigLoaderService.getConfigObjectKey('ENV');
        console.log('auth service env:' + env);
        if (env == 'prod') {
          if (roles != undefined && roles.includes('OPTUMCARE_PDI_USER'))
            this.isPdiUser = true;
          if (roles!= undefined && roles.includes('OPTUMCARE_PDI_ADMIN'))
            this.isPdiAdmin = true;
        } 
        else {
          if (roles != undefined && roles.includes('OPTUMCARE_PDI_NONPROD_USER'))
            this.isPdiUser = true;
          if (roles!= undefined && roles.includes('OPTUMCARE_PDI_NONPROD_ADMIN'))
            this.isPdiAdmin = true;
        }
      } 
      return isAuthenticated;
    }));
  }

  getAccessToken(): string {
    var token: string = "";
    this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
      let token = accessToken;
      //console.log("getAccessToken - AuthToken: " + token);
    });
    return token;
  }
  
  logout(): void {
    this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
  }
}
