import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-layout',
    templateUrl: './user-layout.component.html',
    styleUrls: ['./user-layout.component.scss'],
    standalone: false
})
export class UserLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
