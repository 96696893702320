import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentStorageService } from 'src/app/core/services/current-storage.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from 'src/app/app-store/services/user.service';
import { RegistrationService } from 'src/app/views/registration/registration.service';
import { DatePipe } from '@angular/common';
import { IProviderAttestationUser } from 'src/app/app-store/entities/user';
import { IRegistrationStatus, IVendorRegistration } from 'src/app/app-store/entities/registration';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss'],
    standalone: false
})

export class CallbackComponent implements OnInit {

  constructor(
    private router: Router,
    private currentStorageService: CurrentStorageService,
    public oidcSecurityService: OidcSecurityService,
    private userService: UserService,
    private registrationService: RegistrationService,
    private datePipe: DatePipe,
    private runtimeConfigLoaderService: RuntimeConfigLoaderService
  ) { }

  token: string;

  ngOnInit(): void {
    //debugger;
    this.oidcSecurityService.isAuthenticated$.subscribe(
      ({ isAuthenticated }) => {
        if (isAuthenticated) {
          this.oidcSecurityService.getAccessToken().subscribe(accessToken => {
            this.token = accessToken;
            this.userService.saveUser(this.token).subscribe({
              next: async (registrationStatus: IProviderAttestationUser) => {
                //debugger;
                console.log('Authenticated');
                this.oidcSecurityService.getPayloadFromAccessToken(false).subscribe((result) => {
                  //debugger;
                  this.currentStorageService.userName = result.preferred_username;
                  this.currentStorageService.userFullName = result.name;
                  
                  console.log('token payload roles:' + result.roles);                                                                                                                    
                  let roles = result.roles;
                  //debugger;
                  let env = this.runtimeConfigLoaderService.getConfigObjectKey('ENV');
                  console.log('callback env:' + env);
                  if ((env != undefined && env != 'prod' && roles != undefined && (roles.includes('OPTUMCARE_PDI_NONPROD_USER') || roles.includes('OPTUMCARE_PDI_NONPROD_ADMIN'))) || //) {
                    (env != undefined && env == 'prod' && roles != undefined && (roles.includes('OPTUMCARE_PDI_USER') || roles.includes('OPTUMCARE_PDI_ADMIN')))) {
                    this.currentStorageService.userType = "pdi";
                    this.router.navigate(['/admin']);
                  } else {
                    this.oidcSecurityService.getState().subscribe(registrationCode => {
                      //debugger;
                      if (registrationCode == "pdi") {
                        this.router.navigate(['/forbidden']);
                      } else {
                        this.registrationService.getRegistrationStatus(registrationCode, this.token).subscribe({
                          next: async (registrationStatus: IRegistrationStatus) => {
                            console.log('registration state' + registrationStatus?.registrationState)
                            //debugger;      
                            if (registrationStatus.userDisabled == true) {
                              this.router.navigate(['/forbidden/4']);
                            }
                            switch (registrationStatus.registrationState) {
                              case 'AVAILABLE': {
                                this.router.navigate([`/registration/${registrationCode}`]);
                                break;
                              }
                              case 'REGISTERED': {
                                this.router.navigate(['/select']);
                                break;
                              }
                              case 'EXPIRED': {
                                this.router.navigate(['/forbidden/2']);
                                break;
                              }
                              case 'INVALID': {
                                this.router.navigate(['/forbidden/1']);
                                break;
                              }
                              case 'CLOSED': {
                                this.router.navigate(['/forbidden/5'])
                                break;
                              }
                              default: {
                                this.router.navigate(['/forbidden']);
                                break;
                              }
                            }
                          },
                          error: (e) => {
                            console.error('get registration status request failed with error - ' + JSON.stringify(e));
                          }
                        });
                      }
                    });
                  }
                });
              },
              error: (e) => {
                console.error('save user failed with error - ' + JSON.stringify(e));
              }
            });
          });
        }
      }
    );
  }
}
